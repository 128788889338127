// Variables
@import "custom";
@import "table";
@import "swipe";

$nav-item-line: 1px solid rgba(white, .2);

body {
  color: $text-color;
  font-weight: 400;
  letter-spacing: -0.03em;
  word-break: keep-all;

  @include media-breakpoint-down(sm) {
    padding-top: 50px;
    font-size: .9rem;
    overflow-x: hidden;
  }

  &.frontpage {
  }

  &:not(.frontpage) {
    #quick-icons {
      display: none;
    }
  }
}

.mobile {
  @media (min-width: 481px) {
    display: none !important;
  }
}

.desktop {
  @include media-breakpoint-down(sm) {
    display: none !important;
  }
}

.invalid-feedback {
  display: block;
}

.table-responsive {
  .table {
    th, td {
      white-space: nowrap;
    }
  }
}

.slick-prev:before, .slick-next:before {
  font-family: 'slick' !important;
  color: black !important;
}

.pattern {
  &.pattern-slash {
    background-image: url(/images/pattern/slash-pattern.png) repeat;
    background-size: 70%;
  }
}

.btn {
  .feather {
    position: relative;
    width: 1em;
    height: 1em;
    top: -0.2em;
  }
}

.feather {
  position: relative;
  width: 1.12em;
  height: 1.12em;
  top: -0.12em;
}

.toast {
  .toast-body {
    word-break: break-all;
  }
}

#search-section {
  background-color: transparentize(#3B4755, .3);
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 10px;
  right: 0;
  width: 260px;
  z-index: 1070;
  @include media-breakpoint-down(sm) {
    position: fixed;
    align-items:flex-start;
    top: 50px;
    right: 0;
    max-width:100vw;
  }

  #search-form {
    border: 4px solid $theme-secondary-color;
  }
}

#top-bar {
  color: $theme-color;
  width: 100%;
  font-size: 14px;
  padding-top: 20px;
  padding-bottom: 20px;

  a {
    color: $theme-color;

    &:hover {
      color: darken($theme-color, 10%);
    }

    .feather {
      position: relative;
      width: 1.12em;
      height: 1.12em;
      top: -0.12em;
    }

    .social-link {
      font-size: 16px;
    }

    &:hover {
      color: $theme-color;
    }
  }

  .logo-cell {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .top-logo {
    img {
      width: 100%;
      max-width: 200px;
    }
  }

  #top-social-links {
    .nav-item {
      .nav-link {
        padding: 0 4px;

        img {
          width: 32px;
          height: 32px;
        }
      }

      &:first-child > .nav-link {
        padding-left: 0;
      }

      &:last-child > .nav-link {
        padding-right: 0;
      }
    }
  }

  @include media-breakpoint-down(sm) {
    display: none;
  }
}

header {
  background-color: white;
  display: block;
  position: relative;
  width: 100%;
  transition: position .4s ease;
  z-index: 1050;

  @include media-breakpoint-down(sm) {
    height: 50px;
    padding-top: 0;
    position: fixed;
    top: 0;
    max-width:100vw;
    z-index: 3000;
  }

  #top-bar {
    font-size: 12px;
    .main-logo {
      max-width:240px;
    }
  }

  &.frontpage {
    background-color: transparent;
    @media (max-width: 767px) {
      padding-top: 0;
      position: relative;
      top: 0;
      height: auto;
    }
  }

  &:not(.frontpage) {
    #top-bar {
      color: white;
    }

    @include media-breakpoint-down(sm) {
      height: 50px;
    }
  }

  .navbar-brand {
    background-color: transparent;
    font-size: 1.8em;
    font-weight: 700;
    color: $theme-color;
    img {
      max-width:100px;
    }
  }

  #mobile-nav-toggler {
    display: none;
    position: absolute;
    left: 15px;
    top: 13px;
    width: 32px;
    height: 32px;
    padding:0;

    &:focus, &:active {
      outline: 0;
    }

    .hamburger-box {
      width: 32px;
      height: 24px;
      left: 0;
      top: 0;
      margin: 0;
      position: absolute;
    }

    @include media-breakpoint-down(sm) {
      display: block;
    }
  }

  #main-nav-wrap {
    background-color: #1b3369;
    padding: 0;
    position: relative;
    width: 100%;
    transition: all 200ms ease-out;

    &.on-top {
      position: fixed !important;
      top: 0;
      width: 100%;
      z-index: 3000;

      .hamburger-inner {
        background-color: white;

        &:before {
          background-color: white;
        }

        &:after {
          background-color: white;
        }
      }
    }

    &::after {
      content: '';
      height: 1px;
      clear: both;
    }

    @include media-breakpoint-down(sm) {
      background-color:white;
      height: 50px;
      position: fixed;
      top: 0;
      box-shadow: 0 3px 4px rgba(black, .2);
      .navbar-brand.mobile {
        display: block !important;
        position: absolute;
        left: calc(50% - 55px);
        top: 0;
        margin:0;

        img {
          width: 110px;
          height: auto;
        }
      }
    }

    @include media-breakpoint-up(md) {
      .navbar-brand.mobile {
        display: none !important;
      }
    }
  }

  #main-nav-mega {
    top: 160px;
  }

  .mobile-search {
    position: absolute;
    right: 1em;
    top: 1em;

    .feather {
      width: 1.2rem;
      height: 1.2rem;
      stroke: black;
    }
  }

  #main-navigation {
    width: 100%;
    height: 60px;
    font-size: .9em;
    padding-left: 0;
    padding-right: 0;
    position: relative;
    right: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include media-breakpoint-down(sm) {
      display: block;
      background-color: lighten($theme-secondary-color, 1%);
      position: fixed;
      top: -100vh;
      padding-top: 0;
      padding-bottom: 0;
      opacity: 0;
      transition: all 300ms cubic-bezier(.03, 1, .03, .99);
      &.show {
        opacity: 1;
        top: 50px;
        left:0;
        right:0;
        height: 100%;
        overflow-y:auto;
        position:fixed;
        z-index: 1050;
      }
    }

    nav[data-nav-name="main"] {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      height: 60px;
      font-size: 1em;

      .feather {
        position: relative;
        width: .8em;
        height: .8em;
      }

      .nav-item {
        position: relative;

        .nav-link {
          color: white;
          padding: .5rem 1rem;
          font-size: 16px;
        }

        &.nav-depth-0 {
          &.nav-main-menu-toggle {
            justify-self: flex-end;
            flex: 0;
          }

          > .nav-link {
            text-align: center;
            font-weight: bold;
          }

          .feather {
            display: inline-block;
            width: 1.1rem;
            height: 1.1rem;
            position: static;
          }
        }

        &.active {
          &::after {
            content: '';
            position: absolute;
            width: 100%;
            bottom: -10px;
            height: 4px;
            background-color: #ffe78f;
            @include media-breakpoint-down(sm) {
              display: none;
            }
          }
        }

        &:hover {
          &:not(.nav-depth-0) {
            background-color: darken(#4863C2, 10%);
          }
        }

        .nav-child {
          display: none;
          box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.2);
          z-index: 1060;

          .nav-item {
            border-bottom: 1px solid rgba(white, .2);
          }

          .nav-link {
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: .9em;

            &.active {
              background-color: darken($theme-secondary-color, 10%);
            }
          }
        }

        > .nav-child {
          //depth 1
          flex-direction: column;
          background-color: #252b40;
          position: absolute;
          width: 180px;
          left: 0;
          top: 50px;
          overflow: visible;
          font-size: .9rem;

          .nav-child {
            background-color: #0e1018;
            display: none;
            position: absolute;
            left: 180px;
            top: 0;
          }
        }

        &:hover, &.show, &.selected {
          & > .nav-child {
            display: flex;
          }
        }
      }

    }

    @include media-breakpoint-down(sm) {
      nav[data-nav-name="main"] {
        flex-wrap: wrap;
        align-items: flex-start;
        height: 100%;
        overflow-y: auto;
        padding-bottom: 60px;
        background-color: #1a2834;
        width:100vw;

        .nav-item:not(.nav-depth-0) {
          &:hover {
            background-color: $theme-color;
          }
        }

        .nav-item.nav-depth-0 {
          flex-basis: 33%;
          margin-bottom: 16px;

          &.nav-main-menu-toggle {
            display: none;
          }

          > .nav-link {
            font-size: 14px;
            font-weight: 700;
            text-shadow: 0 0 4px rgba(black, .5);
            background-color: $color-1;
            border-right: 1px solid rgba(white, .2);

            &:nth-child(4n-1) {
              border-right: 0;
            }
          }

          .nav-child {
            display: flex;
            flex-direction: column;
            position: static;
            box-shadow: none;
            background: none;
            margin: 10px 0 0 0;
            width: auto;

            .nav-item {
              border-bottom: 0;

              .nav-link {
                display: inline-block;
                font-size: 14px;
                text-align: center;
                padding: .45em;
                width: 100%;

                &.active {
                  color: $color-1;
                }
              }
            }
          }
        }
      }
    }
  }
}

#main-nav-mega {
  padding: 1rem 0 1.2rem;
  background-color: #001e44;
  position: fixed;
  width: 100%;
  top: 90px;
  box-shadow: 0 4px 8px rgba(black, .5);
  transition: display 300ms ease-in-out;
  z-index: 3000;

  @include media-breakpoint-down(sm) {
    display: none;
  }

  button.close {
    position: absolute;
    top: 1rem;
    right: 1rem;

    .feather {
      color: white;
    }

    &:focus {
      outline: none;
    }

    &:hover {
      .feather {
        color: #c7ff3f;
      }
    }
  }

  nav[data-nav-name="main"].nav {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
    width: 100%;
    max-width: 1100px;
    margin-left: auto;
    margin-right: auto;

    > .nav-item {
      flex-basis: 20%;
      padding-left: .5rem;
      padding-right: .5rem;
      @include media-breakpoint-down(sm) {
        flex-basis: 25%;
      }

      > .nav-link {
        color: white;
        text-shadow: 0 0 4px rgba(black, .5);
        font-weight: 700;
        border-bottom: 1px solid $theme-color;
        margin-bottom: .6rem;
        word-break: keep-all;
      }

      > .nav-child {
        flex-direction: column;

        > .nav-item {
          > .nav-link {
            text-align: center;
            font-size: .9em;
            padding: .4em 0;
            color: white;
            word-break: keep-all;
            line-height: 1.2;

            &:hover {
              color: #6bc9f6;
            }
          }
          > .nav-child {
            flex-direction: column;
            .nav-item {
              .nav-link {
                text-align:center;
                color: white;
                font-size:.8em;
                word-break: keep-all;
                line-height:1.2;
                &:hover {
                  color: #6bc9f6;
                }
              }
            }
          }
        }
      }
    }

    .nav-depth-0 {
      > .nav-link {
        color: #fff2ea;
      }
    }

    #search, .nav-main-menu-toggle {
      display: none !important;
    }
  }
}

#frontSlider {
  position: relative;
  z-index: 1000;

  @include media-breakpoint-down(sm) {
    top: 0;
    margin-bottom: 30px;
  }

  .carousel-item {
    .slide-background {
      display: block;
      max-width: 2560px;
      margin-left: auto;
      margin-right: auto;
      background-repeat: no-repeat !important;
      background-position: center !important;
      background-size: cover !important;
      overflow: hidden;
      position: relative;
    }

    .desktop {
      @include media-breakpoint-down(sm) {
        display: none !important;
      }

      img {
        width: 100%;
        height:600px;
      }
    }

    .mobile {
      @media screen and (min-width: 481px) {
        display: none !important;
      }
      width: 100%;
      min-height:180px;
    }
  }

  .carousel-indicators {
    li {
      width: 12px;
      height: 12px;
      border-radius: 6px;
      box-shadow: 0 0 4px rgba(black, .2);
      border-top: 0;
      border-bottom: 0;
    }
  }
}

// 메인 배너
.carousel-banners {
  margin-left: -15px;
  margin-right: -15px;

  .slick-slide {
    margin: 0 15px;
  }

  .banner-item {
    .banner-img {
      max-width: 100%;
      transition: all 300ms ease-in-out;

      &:hover {
        transform: scale(1.05);
      }
    }
  }
}

#page-header {
  background-color: $page-header-color;
  background-image:url(/images/bg/page-header-bg.png);
  background-position-x: 100%;
  background-position-y: 50%;
  background-repeat: no-repeat;

  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  position: relative;
  top: 0;
  width: 100%;
  height: 120px;
  z-index: 1020;

  @include media-breakpoint-down(sm) {
    height: 110px;
    color: #eae4e7;
    background-position-x: 173%;
    background-size: 80%;
  }

  h1.page-title {
    color: $color-2;
    font-size: 2rem;
    font-weight: 700;
    margin-bottom: 0;
    letter-spacing: -0.02em;
    position: relative;
    text-shadow: 0 0 10px rgba(darken($theme-color, 30%), .8);
    z-index: 1050;
    @include media-breakpoint-down(sm) {
      text-align: center;
      font-size: 20px;
      padding-bottom: 0;
    }
  }
}

// breadcrumb
nav[aria-label="breadcrumb"] {
  position: relative;
  z-index: 1050;

  @include media-breakpoint-down(sm) {
    display: block;
    text-align: center;
  }

  .breadcrumb {
    border-radius: 30px;
    background-color: rgba(black, .3);
    justify-content: center;
    margin-bottom: 0;
    padding: .5rem 1rem;
    @include media-breakpoint-down(sm) {
      padding: 4px 10px;
      display: inline-flex;
    }

    .breadcrumb-item {
      font-weight: 400;
      font-size: 12px;

      &:not(:first-child) {
        &::before {
          display: inline-block;
          content: '|';
          font-weight: 300;
          color: white;
        }
      }

      a {
        color: white;

        &:hover {
          text-decoration: none;
          color: lighten($theme-color, 10%);
        }
      }

      &.active {
        color: #fff6af;
      }
    }
  }
}

// go to top
#go-top {
  background-color: rgba(black, .3);
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  right: 10px;
  bottom: 10px;
  z-index: 1050;

  a {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    line-height: 32px;
    text-align: center;
    color: white;

    &:hover {
      color: $theme-color;
    }
  }
}

aside {
  &:after {
    content: '';
    clear: both;
  }

  @include media-breakpoint-down(sm) {
    display: none;
  }
}

main {
  @include media-breakpoint-down(sm) {
    padding: 0;
    & > .gap {
      display: none;
    }
  }
}

.btn.btn-min-80 {
  min-width:80px !important;
}
.btn.btn-min-100 {
  min-width:100px !important;
}

.latest-section {
  margin-bottom:80px;

  h2 {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    position: relative;
    margin-bottom:40px;

    @include media-breakpoint-down(sm) {
      flex-direction:column;
      align-items:center;
    }

    .ko {
      color: darken($theme-color, 20%);
      font-weight: 700;
      @include media-breakpoint-down(sm) {
        font-size:20px;
      }
    }

    .en {
      color:#666666;
      font-size: .8em;
      font-weight: 300;
      @include media-breakpoint-down(sm) {
        font-size:14px;
        margin-top:5px;
      }
    }


    &:after {
      display: block;
      content: '';
      position: absolute;
      width: 100%;
      left: 0;
      bottom: -10px;
      height: 1px;
      border-bottom: 1px solid rgba($theme-color, .5);
    }
  }

  .post-item {
    display:flex;
    align-items:center;
    @include media-breakpoint-down(sm) {
      align-items:flex-start;
    }
    figure {
      margin-bottom:0;
      flex:none;
      width:120px;
      height:120px;
      background-size:cover !important;
      background-position:50% 50% !important;
      border-radius:60px;
      overflow:hidden;
      box-shadow:4px 4px 8px rgba(black, .2);
      @include media-breakpoint-down(sm) {
        width:60px;
        height:60px;
      }

      img {
        width:120px;
        height:120px;
        @include media-breakpoint-down(sm) {
          width:60px;
          height:60px;
        }
      }
    }
    .wrapper {
      display:flex;
      flex-direction:column;
      padding-left:20px;
      .category {
        color:$theme-color;
        font-size:18px;
        @include media-breakpoint-down(sm) {
          font-size:12px;
        }
      }
      .title {
        font-size:20px;
        font-weight:700;
        @include media-breakpoint-down(sm) {
          font-size:16px;
        }
      }
      .date {
        color:#666666;
        font-size:14px;
        @include media-breakpoint-down(sm) {
          font-size:12px;
        }
      }
    }
    &.has-excerpt {
      .category {
        font-size:16px;
        @include media-breakpoint-down(sm) {
          font-size:12px;
        }
      }
      .date {
        margin-top:0;
        @include media-breakpoint-down(sm) {
          font-size:12px;
        }
      }
      .excerpt {
        font-size:16px;
        color:#666666;
        @include media-breakpoint-down(sm) {
          font-size:12px;
          margin-top:5px;
        }
      }
    }
  }

  .card {
    transition:all 200ms ease-in-out;
    &:hover {
      transform:scale(1.02);
      @extend .shadow;
    }
  }

  a {
    .post-item {
      transition:all 200ms ease;
    }
    &:hover {
      .post-item {
        transform:scale(1.02);
      }
    }
  }
}


.side-widget {
  .sidemenu-title {
    background: darken($theme-color, 10%) url(/images/bg/side-title-bg.png);
    font-size: 1.25em;
    font-weight: 700;
    color: white;
    text-shadow: 0 0 4px rgba(0, 0, 0, .4);
    position: relative;
    padding-top: 1em;
    padding-bottom: 1em;
    margin-top: 0;
  }

  .latest-post {
    .latest-post-item {
      font-size: .9em;
      padding: 14px 0 0;
      line-height: 1.4;

      .thumbnail {
        flex: none;
        width: 60px;
        height: 60px;
        margin-right: 10px;
        overflow: hidden;

        img {
          width: 100%;
          height: auto;
        }
      }

      a {
        line-height: 1.6;
      }

      small {
        font-size: 12px;
      }
    }
  }
}

#sidemenu-container {
  position: relative;
  top: 0;
  z-index: 1020;
  @include media-breakpoint-down(sm) {
    // display: none;
  }
}

#submenu {
  background-color: $submenu-color;
  box-shadow: 0 0 8px rgba(black, .2);
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: nowrap;
  height: 60px;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;

  @include media-breakpoint-down(sm) {
    border: 0;
    flex-wrap: wrap;
    height: auto;
    padding: 10px 10px 16px;
  }

  @include media-breakpoint-between(sm, md) {
    .nav-link {
      font-size: .9rem;
    }
  }

  .nav-item {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
    position: relative;

    &::after {
      display: block;
      content: '';
      position: absolute;
      border-right: 3px dotted rgba(171, 172, 235, 0.62);
      top: 35%;
      right: -2px;
      height: 30%;
      width: 1px;
      @include media-breakpoint-down(sm) {
        top: 1.12em;
        border-right-color: #d8d8d8;
      }
    }

    &:last-child {
      &::after {
        border-right: 0;
      }
    }

    .nav-link {
      color: white;
      padding: .85rem 1.2rem;
      word-break: keep-all;
      white-space: normal;

      @include media-breakpoint-down(sm) {
        color: white;
        padding: 10px 10px 0;
      }

      .feather {
        color: $theme-color;
        width: .85rem;
        height: .85rem;
        position: relative;
        top: -0.1em;
        left: -0.25em;
        transition: transform 300ms ease-in-out;
        @include media-breakpoint-down(sm) {
          display: none;
        }
      }

      &:hover {
        color: #fad100;
        transform: scale(1.1);
        font-weight: 700;
      }

      &.active {
        color: #fad100;
        font-weight: 700;

        .feather {
          background-color: white;
          border: 1px solid $theme-color;
        }
      }
    }
  }

  .nav-children {
    &.collapse {
      display:none;
    }
    &.collapse.show {
      display: flex;
    }
    > .nav-item {
      &:after {
        display:none;
      }
      > .nav-link {
        padding: .8rem 1rem .8rem 2.5rem;
      }
    }

    @include media-breakpoint-up(sm) {
      position: absolute;
      top: 60px;
      background: cornflowerblue;
      min-width: 220px;
      flex-direction: column;
      align-items: flex-start;
      z-index: 1080;
    }
  }


}

@keyframes onair {
  from {
    color: #faff00;
  }
  to {
    color: white;
  }
}

#youtube-channel-info {
  background-color: #eeeeee;
  .container {
    @include media-breakpoint-down(sm) {
      padding-left:0;
      padding-right:0;
    }
    .nav {
      flex-wrap:nowrap;
      justify-content: space-between;
      padding:0 10px;
      @include media-breakpoint-down(sm) {
        flex-wrap:wrap;
        padding: 16px 10px 10px;
      }
      .nav-item.nav-link {
        img {
          width:24px;
          height:24px;
          margin-right:5px;
        }
        .label { font-size:16px; }
      }
    }
  }

  .wrapper {
    display: flex;
    align-items: center;
  }

  figure {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #a72323;
    margin: 0;
    padding: 10px 20px 10px 0;

    @include media-breakpoint-down(sm) {
      img {
        max-width:40px;
        height:40px;
      }
    }

    figcaption {
      margin-left: 16px;
      color: white;

      .title {
        font-weight: 700;
        font-size: 22px;
        @include media-breakpoint-down(sm) {
          font-size:18px;
        }
      }

      .sub-title {
        font-size: 16px;
        font-weight: 300;
        @include media-breakpoint-down(sm) {
          font-size:14px;
        }
      }
    }
  }

  .nav {
    background-color: #eeeeee;
    @include media-breakpoint-down(sm) {
      padding-top:10px;
      padding-bottom:10px;
    }

    .nav-item.nav-link {
      display: flex;
      align-items: center;
      padding-top:5px;
      padding-bottom:5px;

      img {
        width: 32px;
        height: 32px;
        margin-right: 10px;
      }

      .label {
        font-size: 20px;
        font-weight: 700;
      }
    }

    @include media-breakpoint-down(sm) {
      .nav-item.nav-link {
        flex-basis:33.333%;
        display:flex;
        flex-direction:column;
        align-items:center;
        justify-content:center;
        margin-bottom:5px;
        img {
          margin:0 0 5px;
        }
        .label {
          font-size:16px;
        }
      }
    }
  }
}

#livecast-container {
  background-color: #eeeeee;
  min-height: 80px;
  @include media-breakpoint-down(sm) {
    min-height: initial;
  }

  .live {
    color: #faff00;
    font-weight: 700;
    font-size: 1.3rem;
    animation-duration: 500ms;
    animation-name: onair;
    animation-iteration-count: infinite;
    animation-direction: alternate;
  }
}

#quick-link-container {
  background-color:$color-2;
}
.quick-link-banner {
  padding: 40px 0;
  border: 0;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  max-width: 1110px;
  margin-left: auto;
  margin-right: auto;

  @include media-breakpoint-down(sm) {
    border-top: 0;
    border-bottom: 0;
    flex-wrap: wrap;
    padding:30px 0 15px;
    align-items:flex-start;
  }

  .mid-banner {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-bottom: 20px;

    @include media-breakpoint-down(sm) {
      flex-basis: 33.333%;
      height: 60px;
      padding: 3px;
      border-right: 0;
      margin: 0 0 20px 0;
      align-items:flex-end;
    }

    a {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      position: relative;
      color: white;
      transition: all 200ms ease-in-out;
      @include media-breakpoint-down(sm) {
        flex-direction: column;
        justify-content: flex-end;
      }

      &:hover {
        cursor: pointer;
        transform: scale(1.1);
        color: #94d5f6;
      }
    }

    .mid-banner-icon {
      flex: none;
      width: 60px;
      height: 60px;
      @include media-breakpoint-down(sm) {
        width: 32px;
        height: 32px;
      }
    }

    .mid-label {
      font-size: 18px;
      font-weight: 700;
      line-height: 1.2;
      text-align:center;
      padding-top:10px;

      small {
        font-size: 14px;
        font-weight: 300;
        color: $color-3;
      }

      @include media-breakpoint-down(sm) {
        font-size: 14px;
        display: inline-block;
        line-height: 1;
        width: 100%;
        text-align: center;
        small {
          display:none;
        }
      }
    }
  }
}

#latest-sermon-and-choir {
  background: url('/images/front/latest-sermon-bg.png') rgba(#1a3356, .98) no-repeat right center ;
  background-size: cover;
  color: white;
  padding-top: 80px;
  padding-bottom: 80px;
  margin-bottom:20px;
  position: relative;

  @include media-breakpoint-down(sm) {
    padding-top:40px;
    padding-bottom:40px;
  }

  h2 {
    position: relative;
    display:flex;
    justify-content: space-between;
    align-items:flex-end;
    @include media-breakpoint-down(sm) {
      flex-direction:column;
      align-items:center;
    }

    .ko {
      font-weight: 700;
      @include media-breakpoint-down(sm) {
        font-size:24px;
      }
    }

    .en {
      font-size: .8em;
      font-weight: 300;
      @include media-breakpoint-down(sm) {
        font-size:14px;
        margin-top:5px;
      }
    }

    &:after {
      display: block;
      content: '';
      position: absolute;
      width: 100%;
      left: 0;
      bottom: -10px;
      height: 1px;
      border-bottom: 1px solid rgba(white, .5);
    }
  }
}

#body-container {
  padding-top:60px;
  padding-bottom:60px;
  @include media-breakpoint-down(sm) {
    margin-top:30px;
    padding-top:0;
  }
  &.frontpage {
    padding-top:0;
    @include media-breakpoint-down(sm) {
      margin-top:0;
    }
  }



  h2 {
    @include media-breakpoint-down(sm) {
      font-size: 1.4rem;
      small {
        font-size: 1rem;
      }
    }
  }

  .card-deck {
    @include media-breakpoint-down(sm) {
      display: flex;
      flex-wrap: wrap;
      .card {
        flex-basis: calc(50% - 10px);
        margin: 0 5px 20px;
      }
    }
  }
}

.icon-tree {
  position: relative;

  &:before {
    width: 32px;
    height: 32px;
    display: block;
    float: left;
    margin-right: 10px;
    margin-bottom: 10px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    content: "";
  }

  &.icon-tree-1 {
    &:before {
      background-image: url('/images/icon/icon-tree-1.png');
    }
  }

  &.icon-tree-2 {
    &:before {
      background-image: url('/images/icon/icon-tree-2.png');
    }
  }

  &.icon-tree-3 {
    &:before {
      background-image: url('/images/icon/icon-tree-3.png');
    }
  }
}

.excerpt {
  a {
    text-decoration: none;
    color: lighten($theme-color, .4);

    &:hover {
      color: $theme-color;;
      text-decoration: inherit;
    }
  }
}

// public loop
.loop-container {
  display: flex;
  flex-wrap: wrap;
  margin-left: -.5rem;
  margin-right: -.5rem;
  @include media-breakpoint-down(sm) {
  }
}

.loop-row {
  flex-basis: calc(25% - 1rem);
  border: 1px solid $border-color;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 0 .5rem 1.5rem;
  transition: transform 200ms ease-in-out;
  @include media-breakpoint-down(sm) {
    align-items: center;
    flex-basis: calc(50% - 1rem);
  }

  &:hover {
    transform: scale(1.05);
    cursor: pointer;
  }

  .loop-thumbnail {
    background-size: cover;
    height: 200px;
    overflow-y: hidden;
    flex: none;
    width: 100%;
    order: 1;
    position: relative;

    > a {
      position: absolute;
      width: 100%;
      height: 100%;
    }

    @include media-breakpoint-down(sm) {
      width: 100%;
      height: 122px;
    }
  }
}

.loop {
  display:flex;
  flex-direction: column;
}

.loop-item {
  width:100%;
  display:flex;
  justify-content:flex-start;
  align-items:center;
  padding:10px 0;

  @include media-breakpoint-down(sm) {
    flex-direction:column;
    padding: 20px 10px;
  }

  .loop-no {
    flex-basis:8%;
    color: $primary;
    padding-left:5px;

    @include media-breakpoint-down(sm) {
      display:inline-block;
      border-radius:20px;
      background-color: $primary;
      color:white;
      padding-left:10px;
      padding-right:10px;
      margin-bottom:10px;
    }
  }

  .loop-title {
    flex-basis:67%;
    line-height: 1.2;
    font-weight:600;
    font-size: 18px;

    .title-link {
      color: $text-color;
      font-size: 1.12rem;

      &:hover {
        color: $theme-color;
        text-decoration: none;
      }

      @include media-breakpoint-down(sm) {
        display: block;
        font-size: .9rem;
        line-height: 1.4;
      }
    }
  }

  .loop-post-info {
    flex-basis:25%;
    display: flex;
    align-items:center;
    margin-right: 1em;
    padding:5px 0;

    .loop-post-info-item {
      color: $theme-secondary-color;
      font-size: .9rem;
      margin-right: 1em;
      &:last-child {
        margin-right:0;
      }
      @include media-breakpoint-down(sm) {
        font-size: .8em;
      }

      .loop-post-label {
        color: $theme-color;
      }
    }
  }

  .tags {
    .tag {
      @extend .text-muted;
      font-size: .8em;
    }
  }
}

.grid-loop {
  .card {
    transition: all 300ms ease-in-out;
    &:hover {
      transform:scale(1.02);
      @extend .shadow;
    }
  }
}

.notice-row {
  background-color: #F9F2E5;
}


.badge-theme {
  background-color: lighten($theme-color, 20%);
  color: white;
}

.excerpt {
  font-size: .9em;
}

.gap {
  display: block;
  width: 100%;
  height: 40px;
  clear: both;
  @include media-breakpoint-down(sm) {
    height: 20px;
  }
}

#news-letter {
  padding: 40px 0;
  background-image: url(/images/bg/calendar-bg.jpg);
  background-position: -300px 0;
  background-size: cover !important;
  position: relative;

  @include media-breakpoint-down(sm) {
    .row {
      .col-sm-6 {
        &:first-child {
          order: 2;
          padding-top: 32px;
          z-index: 1040;
        }

        &:last-child {
          order: 1;
          z-index: 1040;
        }
      }
    }
    &:after {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background-color: rgba(black, .3);
    }
    margin-bottom: 32px;
  }

  h2 {
    text-shadow: 0 0 4px rgba(black, .5);
    @include media-breakpoint-down(sm) {
      font-size: 1.4rem;
      text-align: center;
      + p {
        text-align: center;
      }
    }
  }

  .news-letter-items {
    .news-letter-item {
      display: flex;
      flex-direction: column;
    }

    a {
      display: inline-block;
      color: #51575e;
      font-size: 1rem;
      padding: .2em 0;
      @include media-breakpoint-down(sm) {
        font-size: .95em;
        padding: .1em 0;
        color: white;
      }

      &:hover {
        color: $theme-color;
      }

      .date {
        font-size: .85em;
        color: #3d0faa;
      }
    }
  }
}

#frontpage-events {
  padding: 40px 15px;
  @include media-breakpoint-down(sm) {
    padding: 12px 30px;
  }

  .slick-current {
    &:before {
      content: '';
      position: absolute;
      width: 100%;
      top: 34px;
      border: 2px solid lighten(black, 90%);
    }
  }

  .slick-slide {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-basis: 33.333%;

    & > div {
      margin: 0 20px;
    }
  }

  .slick-arrow {
    width: 16px;
    height: 64px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    top: 34%;
    @include media-breakpoint-down(sm) {
      width: 10px;
      height: 32px;
    }

    &:before {
      display: none;
    }

    &.slick-prev {
      background-image: url(/images/arrow-left.png);
      left: -32px;
      @media screen and (max-width: 480px) {
        left: 10px;
      }
    }

    &.slick-next {
      background-image: url(/images/arrow-right.png);
      right: -32px;
      @media screen and (max-width: 480px) {
        right: 10px;
      }
    }
  }

  .event-item {
    position: relative;
    font-size: 1rem;
    @include media-breakpoint-down(sm) {
      font-size: .9rem;
    }

    .date {
      text-align: center;
      font-size: 1.2rem;
      color: $theme-color;
      margin: 0 0 40px;
      position: relative;
      @include media-breakpoint-down(sm) {
        font-size: 1em;
        margin-bottom: 30px;
      }

      &:before {
        content: '';
        width: 16px;
        height: 16px;
        border-radius: 12px;
        border: 4px solid $theme-color;
        background-color: white;
        display: block;
        position: absolute;
        bottom: -14px;
        left: calc(50% - 12px);
        z-index: 100;
        @include media-breakpoint-down(sm) {
          width: 18px;
          height: 18px;
          bottom: -22px;
        }
      }

      &.today {
        &:before {
          background-color: $theme-color;
        }
      }
    }

    .time {
      color: $theme-color;
    }

    .subject {
      text-align: center;
    }

    .place {
      text-align: center;
    }

    .content {
      text-align: center;
    }
  }
}

#events + .text-center {
  margin-bottom: 24px;
}

#events + .text-center > .btn-more {
  font-size: 1rem;

}

#event-gallery {
  @include media-breakpoint-down(sm) {
    .card-deck {
      flex-wrap: wrap;
      flex-direction: row;

      .card {
        flex-basis: calc(50% - 6px);
        margin-bottom: 1em;
        margin-left: 3px;
        margin-right: 3px;
      }
    }
  }
}


.front-card {
  transition: all 200ms ease;
  overflow: hidden;
  border: 0;
  border-radius: 16px;
  position: relative;

  &.bordered {
    border:1px solid rgba(black, .1);
  }

  @include media-breakpoint-down(sm) {
    margin-bottom: 20px;
  }

  &:hover {
    transform: scale(1.02);
    box-shadow: 4px 4px 12px rgba(black, .3);
  }

  a:hover {
    .title {
      color: $theme-color;
    }
  }

  .thumbnail {
    height: 260px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    transition: 0.6s;
    transform: scale(1);
    overflow-y: hidden;
    position: relative;
    @include media-breakpoint-down(sm) {
      margin-bottom: 1em;
    }
  }

  .card-body {
    position: relative;
    color: #666666;
    padding: .5em 1em;
    min-height: 162px;
    position: relative;

    @include media-breakpoint-down(sm) {
      min-height:106px;
    }

    .date {
      position: absolute;
      bottom: 10px;
      width: 100%;
      left: 0;
      margin-bottom: 0;
    }

    .category {
      position: absolute;
      background-color: darken($theme-color, 20%);
      width: 50%;
      color: white;
      border-radius: 16px;
      height: 32px;
      line-height: 32px;
      font-size: 14px;
      left: 25%;
      top: -16px;
      @include media-breakpoint-down(sm) {
        top:-30px;
      }
    }
  }

  .title {
    color: black;
    text-align: center;
    font-weight: 700;
    font-size: 20px;
    letter-spacing: -0.03em;
    margin-top: 20px;
    margin-bottom: 10px;

    @include media-breakpoint-down(sm) {
      font-size: 18px;
      margin-top:5px;
      margin-bottom:5px;
    }
  }

  .bible, .preacher, .date {
    font-size: 14px;
    letter-spacing: -.5px;
    margin-bottom: 6px;
    @include media-breakpoint-down(sm) {
      margin-bottom:5px;
    }
  }

  .preacher {
    @include media-breakpoint-down(sm) {
      display:none;
    }
  }

  .card-text.font-size-sm {
    @include media-breakpoint-down(sm) {
      font-size: .8em;
    }
  }

  .card-excerpt {
    font-size: .9rem;
    color: lighten(black, 50%);
    @include media-breakpoint-down(sm) {
      font-size: 12px;
    }
  }

  .card-footer {
    background-color: white;
    color: #666666;
    font-size: 12px;
    border-top: 0 !important;
    min-height: 20px;

    @include media-breakpoint-down(sm) {
      font-size: .7em;
      padding: 0 12px 12px;
    }
  }

  &:hover {
    cursor: pointer;
    tranform: scale(1.1);
  }
}

.quick-cards {
  padding-top: 60px;
  padding-bottom: 60px;
  margin-bottom:20px;

  @include media-breakpoint-down(sm) {
    padding-top:20px;
    padding-bottom:20px;
  }

  .card {
    border-radius: 16px;
    border: 0;
    margin-bottom:20px;

    a {
      .card-img-top {
        transition: all 100ms ease;
        border-radius: 16px;
      }

      &:hover {
        .card-img-top {
          transform: scale(1.02);
          box-shadow: 4px 4px 10px rgba(black, .3);
        }
      }
    }
  }
}

.loop-container-table {
  display: flex;
  flex-direction: column;

  .single-row {
    display: flex;
    flex-basis: auto;
    padding: .75rem 0;
    flex-direction: row;
    border-bottom: 1px solid rgba($secondary, .4);

    &:last-child {
      border-bottom: 0;
    }

    .column {
      flex: 1;
      display: flex;
      align-items: center;

      &.thumbnail {
        flex: none;
        width: 64px;
        height: 64px;
        overflow: hidden;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        border: 1px solid lighten($secondary, 30%);
        margin-right: 20px;

        img {
          width: 100%;
          height: auto;
        }
      }

      .title {
        font-size: 1rem;
        font-weight: 700;
        margin: 0;
      }

      .meta {
        color: lighten(black, 50%);
        font-size: $font-size-sm;
      }
    }
  }
}

button.play {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  background-color: lighten($theme-color, 20%);
  border: 0;

  .feather {
    color: white;
    top: 0;
    left: .1em;
  }

  &:hover {
    box-shadow: 1px 1px 4px rgba(black, .1);
  }
}

.btn {
  &.btn-facebook {
    background-color: #4366B0;
    color: #FFFFFF;

    &:hover {
      background-color: rgba(42, 64, 110, 0.98);
    }
  }

  &.btn-google {
    background-color: rgba(198, 72, 82, 0.98);
    color: #FFFFFF;

    &:hover {
      background-color: rgba(123, 45, 51, 0.98);
    }
  }

  &.btn-kakao {
    background-color: rgba(254, 230, 56, 0.98);
    color: rgba(64, 25, 31, 0.98);

    &:hover {
      background-color: rgba(184, 141, 60, 0.98);
    }
  }

  &.btn-naver {
    background-color: #15C643;
    color: #FFFFFF;

    &:hover {
      background-color: #108B31;
    }
  }
}

i.sb {
  display: inline-block;
  width: 32px;
  height: 32px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  &.sb-facebook {
    content: '';
    background-image: url('/images/social-login/social-facebook.png');
  }

  &.sb-google {
    content: '';
    background-image: url('/images/social-login/social-google.png');

  }

  &.sb-naver {
    content: '';
    background-image: url('/images/social-login/social-naver.png');
  }

  &.sb-kakao {
    content: '';
    background-image: url('/images/social-login/social-kakao.png');
  }
}

.social-login-btn-group {
}

.entry-content {
  color: $text-color;

  .entry-single-title {
    font-size: 1.4rem;
    line-height: 1.8em;
    @include media-breakpoint-down(sm) {
      padding: 1em 0;
      font-size: 1.2rem;
      line-height: 1.4;
      text-align: center;
    }
  }

  .post-info {
    display: flex;
    justify-content: flex-start;
    border-bottom: 1px solid #c7d4e1;
    padding-bottom: 1em;
    @include media-breakpoint-down(sm) {
      justify-content: center;
    }

    .post-info-item {
      font-size: .9rem;
      color: $theme-secondary-color;
      margin-right: 20px;

      &:last-child {
        margin-right: 0;
      }

      @include media-breakpoint-down(sm) {
        font-size: .8rem;
      }
    }
  }

  #entry-gallery {
    .col-12.col-sm-12 {
      img {
        max-width: 100%;
        height: auto;
        display: block;
        margin-left: auto;
        margin-right: auto;
      }
    }
  }

  #post-nav {
    .btn {
      min-width: 80px !important;
    }
  }

  .other-post-titles {
    .other-post-item {
      &.prev {
        padding-bottom: .5em;
        margin-bottom: .5em;
        border-bottom: 1px solid #c7d4e1;
      }

      a {
        display: flex;
        font-size: 1rem;
        @include media-breakpoint-down(sm) {
          font-size: .8rem;
        }

        .icon {
          flex-basis: 32px;
          line-height: 45px;

          .feather {
            color: $theme-secondary-color;
          }
        }

        .info {
          display: flex;
          flex: 1;
          flex-direction: column;
          padding-left: 1rem;
          font-size: .9em;

          .date {
            color: #85909a;
            font-size: .8em;
          }
        }
      }
    }
  }

  ol, ul:not(.pagination, .nav) {
    padding-left: 1.2em;
  }

  #book_info {
    .card {
      .card-header {
        @extend .bg-white, .font-weight-bold;
      }
      .table {
        margin-bottom: 0;
        th, td {
          color: $text-color;
          padding-left: 1.25rem;
          padding-right: 1.25rem;
        }
      }
    }
  }

  $number-margin: 5px;

  ol {
    list-style-type: decimal;
    padding-left: 0;

    ol {
      list-style-type: none;
      padding-left: 0;

      li {
        position: relative;
        padding-left: 25px; // 20px에서 25px로 증가

        &:before {
          content: counter(list-item) ")";
          position: absolute;
          left: 0;
          margin-right: $number-margin;
        }

        ol {
          list-style-type: none;
          padding-left: 0;

          li {
            position: relative;
            padding-left: 35px; // 25px에서 35px로 증가

            &:before {
              content: "(" counter(list-item) ")";
              position: absolute;
              left: 0;
              margin-right: $number-margin;
            }
          }
        }
      }
    }
  }

  table.table {
    th, td {
      word-break: keep-all;
    }

    thead {
      th, td {
        background-color: #9bc6e0;
        color: white;
      }
    }

    tbody {
      th {
        color: $color-1;
      }
    }
  }

  blockquote {
    padding-left: 1.5em;
    border-left: 4px;
    border-color: rgba(162, 173, 192, 0.6);
    border-left-style: solid;
    font-weight: 700;

    @include media-breakpoint-down(sm) {
      font-size: .9rem;
    }

    p {
      text-align: justify;
      line-height: 1.8;
    }
  }

  p {
    color: $text-color;
    font-size: 1.12rem;
    word-break: keep-all;
    letter-spacing: -0.02rem;
    line-height: 1.6;
    @include media-breakpoint-down(sm) {
      font-size: 1rem;
    }
  }

  .alert {
    p {
      font-size: 1rem;
    }
  }

  h1, h2, h3, h4, h5, h6 {
    font-weight: 700;
    line-height: 1.6;
  }

  h1 {
    font-size: 2em;
    @include media-breakpoint-down(sm) {
      font-size: 1.85em;
    }
  }

  h2 {
    font-size: 1.8em;
    @include media-breakpoint-down(sm) {
      font-size: 1.65em;
    }
  }

  h3 {
    font-size: 1.6em;
    line-height: 1.2;

    small {
      font-size: .75em;
      color: rgba($theme-color, .7);
    }

    @include media-breakpoint-down(sm) {
      font-size: 1.45em;
    }
  }

  h4 {
    font-size: 1.4em;
    @include media-breakpoint-down(sm) {
      font-size: 1.25em;
    }
  }

  h5 {
    font-size: 1.2em;
    @include media-breakpoint-down(sm) {
      font-size: 1em;
    }
  }

  h6 {
    font-size: 1rem;
    @include media-breakpoint-down(sm) {
      font-size: .85em;
    }
  }

  ol {
    li {
      padding-top: 5px;
      padding-bottom: 5px;

      ul {
        padding-left: 1em;
        list-style-type: square;

        li {
          padding-top: 2px;
          padding-bottom: 2px;
        }
      }
    }
  }

  dl {
    dt {
      color: $theme-secondary-color;
    }
  }

  iframe {
    display: block;
    border: 0;
    @extend .mx-auto;
  }

  img {
    max-width: 100%;
    height: auto;
  }

  .card {
    h6.card-title {
      font-weight: 500;
    }
  }

  #entry-leading {
    position: relative;
    align-items: stretch;
    @include media-breakpoint-down(sm) {
      &:before, &:after {
        display: none;
      }
    }

    .leading-image {
      @include media-breakpoint-down(sm) {
        order: 2;
        margin-top: 20px;
      }
    }

    .leading-content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      font-size: 16px;
      border-top: 1px solid #6446bf;
      border-bottom: 1px solid #6446bf;
      max-width: 56%;

      p:last-child {
        margin-bottom: 0;
      }

      @include media-breakpoint-down(sm) {
        max-width: inherit;
        border: 0;
        padding: 1em;
        h4 {
          text-align: center;
        }
      }
    }

    &.has-bg {
      min-height: 500px;
      background-size: cover;
      background-repeat: no-repeat;
      align-items: center;
      display: flex;
      flex-direction: row;
      justify-content: center;
      background-position: top center;
      padding-left: 5em;
      padding-right: 5em;

      &:before, &:after {
        display: none;
      }

      @include media-breakpoint-down(sm) {
        min-height: 347px;
        padding-left: 2em;
        padding-right: 2em;
        background-position: top center;
      }

      .leading-content {
        width: 100%;
        text-align: center;
        font-size: 1.2em;
        border: 0;

        p {
          text-align: center;
        }

        @include media-breakpoint-down(sm) {
          font-size: 1em;
          padding: 0;
        }
      }
    }
  }

  #mission-system-table {
    @include media-breakpoint-down(sm) {
      min-width: 800px;
    }

    th, td {
      text-align: center;
      vertical-align: middle;
      height: 50px !important;
      padding: .5rem !important;
      line-height: 1.2;
    }

    thead {
      th {
        width: calc(100% / 8);
      }
    }

    font-size: .9em;
    @include media-breakpoint-down(sm) {
      font-size: .8em;
    }

    tbody {
      tr {
        td:first-child {
          min-width: 70px;
        }
      }

      tr:nth-child(even) {
        td {
          background-color: rgba($theme-color, .05);
        }
      }
    }
  }

  .timetable-group {
    margin-bottom: 2rem;

    .timetable-section-title {
      text-align: center;
    }

    .timetable {
      margin-bottom: 30px;

      table {
        @include media-breakpoint-down(sm) {
          font-size: 14px;
        }
      }
    }
  }

  .steps {
    display: flex;
    flex-wrap: wrap;
    margin-left: -0.5rem;
    margin-right: -0.5rem;
    @include media-breakpoint-down(sm) {
      flex-direction: column;
    }

    .step {
      flex: 1;
      margin: 0 .5rem 2rem;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: start;
      position: relative;
      border-top: 4px solid $color-3;
      background: linear-gradient(-45deg, rgba(140, 146, 153, 0.1) 0%, rgba(220, 225, 229, 0.15) 100%);
      @include media-breakpoint-down(sm) {
        flex-direction: column;
        border-radius: .5rem;
      }

      .step-title {
        flex: none;
        color: $color-1;
        background-size: cover;
        background-position: center;
        padding: .5rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 20px;
        margin-top: 20px;

        &.wide {
          width: 250px;
        }

        @include media-breakpoint-down(sm) {
          width: 120px !important;
          height: 120px !important;
          margin-bottom: 1rem;
          word-break: keep-all;
        }

        .level {
          font-size: 1.2rem;
          font-weight: 700;
          text-align: center;
        }

        .term {
          text-align: center;
        }

        img {
          border-radius: 50%;
          box-shadow: 4px 4px 8px rgba(0, 0, 0, .2);
          width: 140px;
          height: 140px;
        }
      }

      h4 {
        padding-left: 1rem;
      }

      p {
        flex: 1;
        padding-left: 1rem;
      }

      .step-content {
        padding: 0 !important;
        margin-bottom: 60px;
        width: 100%;
        @include media-breakpoint-down(sm) {
          padding: 0 !important;
          width: 100%;
        }

        .title {
          background-color: $color-1;
          color: white;
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;
          height: 80px;
          margin-bottom: 24px;
          line-height: 1.2;
          width: 100%;
          font-size: 1.25em;
          font-weight: 800;
        }

        p {
          padding: 0 24px !important;
          font-size: 1rem;
        }

        dl {
          dt {
            font-size: 1.25em;
            color: $theme-secondary-color;
            font-weight: 800;
          }
        }
      }

      &:nth-child(even) {
        .step-content {
          .title {
            background-color: $color-3;
            color: white;
          }
        }
      }
    }
  }

  .no {
    display: inline-flex;
    width: 32px;
    height: 32px;
    background-color: $color-1;
    justify-content: center;
    align-items: center;
    border-radius: 16px;
    font-size: 1.2em;
    font-family: "Lato", sans-serif;
    color: #FFFFFF;
    text-indent: 0 !important;

    &.no-sm {
      font-size: 12px;
      line-height: 12px;
      border-radius: 0;
      padding: 5px 15px;
    }

  }

  .num-top {
    position: relative;
    margin-top: 40px;

    .no {
      position: absolute;
      top: -16px;
      left: calc(50% - 16px);
    }

    .card {
      @include media-breakpoint-down(sm) {
        margin-bottom: 32px;
      }
    }
  }

  .card-with-num {
    margin-top: 32px;

    .card {
      position: relative;
      margin-bottom: 32px;

      .no {
        position: relative;
        top: -16px;
        left: calc(50% - 16px);
      }

      .card-body {
        margin-top: -16px;
      }
    }
  }

  // 페이지별 필요한 css
  #vision {
    .item {
      min-height: 140px !important;
      @include media-breakpoint-down(sm) {
        margin-bottom: 1.3em;
      }
    }
  }

  #disciple {
    .item {
      .no {
        font-size: 2em;
        font-weight: 700;
        color: rgba(66, 135, 195, 0.98);
        max-width: 40px;
      }

      .title {
        font-size: 1.4em;
        font-weight: 700;
        color: rgba(66, 135, 195, 0.98);
        padding-top: 8px;
      }
    }
  }

  #membership {
    .circle {
      background-position: center center;
      background-size: 90%;
      background-repeat: no-repeat;
      text-align: center;
      height: 200px;
      line-height: 200px;
      flex-basis: 25%;
      font-weight: 700;
      font-size: 1.15em;
      @include media-breakpoint-down(sm) {
        flex-basis: 50%;
      }

      &.circle-1 {
        background-image: url('/images/circle-1.png');
      }

      &.circle-2 {
        background-image: url('/images/circle-2.png');
      }

      &.circle-3 {
        background-image: url('/images/circle-3.png');
      }

      &.circle-4 {
        background-image: url('/images/circle-4.png');
      }
    }
  }

  .abstract {
    padding-right: 1em;
  }

  .photo {
    flex-basis: 160px;
  }

  @include media-breakpoint-down(sm) {
    .abstract {
      order: 2;
      padding-right: 0;
      padding-left: 1em;
      flex-basis: calc(100% - 80px);
    }
    .photo {
      flex-basis: 80px;
      order: 1;
    }
  }

  .nf-block {
    background: rgb(255, 237, 247);
    background: linear-gradient(143deg, rgba(255, 237, 247, 0.25674019607843135) 0%, rgba(226, 126, 255, 0.052258403361344574) 100%);
  }

  .nf-block .lead {
    color: #af4998;
    font-weight: 700;
    font-size: 1.4em;
  }

  .edu-course {
    padding-left: 3em;
  }

  .edu-course .v-line {
    position: absolute;
    top: 0;
    left: 1em;
    content: '';
    border-right: 4px solid #FDB3D0;
    height: 100%;
  }

  .edu-course .course {
    display: inline-block;
    position: relative;
    margin-bottom: 1em;
  }

  .edu-course .course h5 {
    color: #9672af;
  }

  .edu-course .course .no {
    display: inline-block;
    position: absolute;
    width: 32px;
    height: 32px;
    font-size: 1.5em;
    font-weight: 700;
    border: 4px solid #FDB3D0;
    border-radius: 50%;
    color: #6d3a82;
    text-align: center;
    line-height: 24px;
    left: -42px;
    top: -6px;
    background-color: white;
  }
}

footer {
  background-color: rgba(12, 14, 21, 0.85);
  font-size: .8rem;

  @include media-breakpoint-down(sm) {
    margin-top: 20px;
  }

  a {
    color: #9fd3f9;

    &:hover {
      text-decoration: none;
      color: white;
    }
  }

  #bottom-social-links {
    .nav-link {
      padding:0 5px;
      img {
        width:26px;
        height:26px;
      }
    }
    .nav-item:first-child > .nav-link {
      padding-left:0;
    }
    .nav-item:last-child > .nav-link {
      padding-right:0;
    }
  }

  .feather {
    width: 1.12em;
    height: 1.12em;
    position: relative;
    top: -0.12em;
  }

  #footer-brand {
    display:flex;
    flex-direction:column;
    font-size:24px;
    font-weight:500;
    img {
      max-width:200px;
    }
    small {
      font-size:16px;
      margin-bottom:2px;
    }
    span {
      letter-spacing:-1px;
    }
  }

  .footer-menu {
    color: #b2b2b2;
    a {
      color:inherit;
    }
  }

  #footer-menu {
    .nav-item {
      &:first-child {
        .nav-link {
          padding-left: 0;
        }
      }

      &:last-child {
        .nav-link {
          padding-right: 0;
        }
      }
    }
    @include media-breakpoint-down(sm) {
      display: flex !important;
      padding-top: 0 !important;
      margin-top:10px;

      .nav-link {
        padding:2px 4px;
      }

      &:last-child {
        .nav-link {
          padding-top: 0;
          padding-left:0;
        }
      }
    }
  }

  .meta-info {
    color:#eee;
    span > b, .feather {
      color: $color-1;
    }

    a {
      color: #eee;
    }
  }
}

// Frontpage
#front-mid-banner {
  margin-top: 3em;
  margin-bottom: 3em;
  @include media-breakpoint-down(sm) {
    margin-top: 2em;
    margin-bottom: 2em;
  }

  .item {
    position: relative;
    height: 180px;
    @include media-breakpoint-down(sm) {
      height: 134px;
    }

    a {
      &:after {
        display: block;
        content: '';
        clear: both;
      }

      img {
        position: absolute;
        top: 0;
        left: 1em;
        width: calc(100% - 2em);
        height: auto;

        &.bnr-front {
          opacity: 1;
          transition: all .5s ease;
        }

        &.bnr-back {
          opacity: 0;
          transition: all .5s ease;
        }
      }
    }

    &:hover {
      .bnr-front {
        opacity: 0;
      }

      .bnr-back {
        opacity: 1;
      }
    }
  }

}

.bg-podcast {
  background-image: url(/images/podcast_thumbnail.jpg);
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 200px;

  a {
    color: #F9EFF3;
    font-size: 1.3em;
    transition: all .4s ease-in-out;

    &:hover {
      color: #FFC401;
    }
  }
}

.sermon-card {
  .sermon-content {
    border: 1px solid #C2BFC2;
    border-left: 0;
  }
}


.front-sermon-card {
  &:hover {
    cursor: pointer;
  }

  .thumbnail {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    img {
      width: 100%;
      height: 322px;
      @include media-breakpoint-down(sm) {
        height: 215px;
      }
    }
  }

  .card-body {
    min-height: 116px;
    padding: 10px;
    @include media-breakpoint-down(sm) {
      min-height: initial;
    }
  }

  .bible {
    text-align: center;
    font-size: .85rem;
    color: $theme-color;
    @include media-breakpoint-down(sm) {
      font-size: .8em;
    }
  }

  .sermon-title {
    text-align: center;
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.4;
    word-break: keep-all;
    @include media-breakpoint-down(sm) {
      font-size: .8em;
    }
  }

  .preacher {
    text-align: center;
    font-size: .85rem;
    color: #5a5a5d;
    @include media-breakpoint-down(sm) {
      font-size: .8em;
    }
  }

  .card-footer, .date {
    text-align: center;
    font-size: .85rem;
    color: lighten(black, 50%);
    @include media-breakpoint-down(sm) {
      font-size: .75rem;
    }
  }
}

.card-action-link {
  transition: all 150ms ease-in-out;

  &:hover {
    box-shadow: 0 0 32px rgba($theme-color, .3);
    transform: scale(1.02);
  }
}




#related-organization {
  h3 {
    font-size: 2rem;
  }
}

.latest-sermon-card-img {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.hover-scale {
  transition: all .25s ease-in-out;

  &:hover {
    transform: scale(1.1);
  }
}

#facebook-feed {
  column-count: 4;
  column-gap: 2em;

  figure {
    display: inline-block;
    margin-top: 1em;
    margin-bottom: 1em;

    a {
      color: rgba(26, 32, 44, 0.9);

      &:hover {
        color: rgba(20, 60, 146, 0.9);
      }
    }
  }

  @include media-breakpoint-down(sm) {
    column-count: 1;
  }
  @media (min-width: 481px) and (max-width: 768px) {
    column-count: 2;
  }
}

#calendar {
  .fc-toolbar {
    .fc-icon {
      font-size: 1em;
    }

    .fc-button {
      font-size: 14px;
    }

    &.fc-header-toolbar {
      flex-wrap: wrap;

      .fc-left {
        order: 2;
      }

      .fc-center {
        text-align: center;
        flex-basis: 100%;
        margin-bottom: 1em;
        order: 1;
      }

      .fc-right {
        order: 3;
      }

      @include media-breakpoint-down(sm) {
        flex-direction: column;
        & > div {
          margin: .5em auto;
        }
        .fc-left {
          order: 2;
          display: none;
        }
        .fc-center {
          order: 1;
        }
        .fc-right {
          order: 3;
        }
        .fc-button {
          padding: .5em;
        }
      }
    }
  }

  .fc-head {
    .fc-day-header {
      padding: .5rem 0;
      font-size: 14px;

      &.fc-sun {
        color: #a73b3a;
      }
    }
  }

  .fc-body {
  }

  .fc-content-skeleton {
    .fc-sun {
      color: #a73b3a;
    }

    .fc-day-number {
      font-size: 13px;
      font-family: Lato, 'NanumSquareRound', sans-serif;
    }

    .fc-content {
      font-family: Lato, 'NanumSquareRound', sans-serif;
      font-size: 12px;

      .fc-time {
        display: block;
      }

      .fc-title {
        &:hover {
          cursor: pointer;
        }
      }
    }
  }
}

.calendar-detail {
  font-size: 14px;
  width: 300px;
  position: absolute;
  z-index: 1050;

  @include media-breakpoint-down(sm) {
    width: calc(100vw - 2em);
    margin-left: 1em;
    margin-right: 1em;
    position: fixed;
  }

  .feather {
    width: 14px;
    height: 14px;
  }

  .close {
    position: absolute;
    right: 10px;
    top: 10px;
  }

  .title {
    color: $theme-color;
  }
}

#upcoming-events {
  background-color: rgba(109, 187, 24, .2);

  h3 {
    color: rgba(109, 187, 24, 0.94);
  }
}

#series-contents {
  .card {
    &:hover {
      cursor: pointer;
    }
  }

  .series-card {
    transition: all 200ms ease-in-out;

    &:hover {
      transform: translate(-.2em, -.2em);
      box-shadow: 0 0 8px rgba(black, .2);
    }

    .img-wrap {
      height: 180px;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;

      .ongoing {
        position: absolute;
        width: 14px;
        height: 14px;
        display: block;
        border-radius: 7px;
        background-color: lime;
        left: 10px;
        top: 10px;

        &::after {
          font-size: 12px;
          content: '진행 중';
          position: absolute;
          left: 16px;
          top: -2px;
          width: 4em;
        }

        &.ended {
          background-color: #716f77;

          &::after {
            content: '완결';
          }
        }
      }
    }
  }
}

#episodes {
  .playing-now {
    td {
      background-color: #775c5a;
      color: white;

      a {
        color: #f1ca06;
      }
    }
  }
}

h4.cell-name {
  color: #5F4D35;
  font-weight: 700;
}

#staffs {
  .card {
    img.card-img-top {
      width: 100%;
      height: auto;
    }

    h4.card-title {
      font-size: 1rem;
      text-align: center;
    }
  }
}

#shuttle {
  .nav-item {
    background-color: #e7e7e7;
    margin-left: .2em;
    margin-right: .2em;
    border-radius: .25rem;
  }
}

.shuttle-group {
  display: block;
  margin-bottom: 2rem;

  .shuttle-group-title {
    font-size: 1.5rem;
    font-weight: 700;
    color: $theme-color;
    flex-basis: 100%;
    margin: 0 0 .5rem;
  }

  .shuttle-items {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -.5rem 1rem;

    .shuttle-items-title {
      font-weight: 700;
      font-size: 1.2rem;
      color: #4675b4;
      flex-basis: 100%;
      margin: 0 .5rem .5rem;
    }

    .shuttle-item {
      flex-basis: calc(20% - 1rem);
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: row;
      padding: .5rem;
      border: 1px solid $theme-secondary-color;
      margin-left: .5rem;
      margin-right: .5rem;
      margin-bottom: .5rem;
      @include media-breakpoint-down(sm) {
        flex-basis: calc(50% - 1rem);
        flex-direction: column;
        justify-content: center;
      }

      .stop {
        text-align: center;
      }

      .time {
        text-align: center;
        color: #2e4c95;
      }
    }
  }


}


.tab-content {
  padding: 2em 0;
}

.play-button {
  color: white;
}

.card-thumbnail {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 180px;
  overflow: hidden;
  transition: all 2s ease;

  &:after {
    display: block;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0);
    z-index: 100;
    transition: all 1s ease;
  }

  &:hover {
    background-size: 200%;
    cursor: pointer;

    &:after {
      display: block;
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, .5);
      z-index: 100;
    }

    .play-button {
      color: rgba(163, 228, 255, 0.89);
      position: relative;
      z-index: 101;
    }
  }
}

#latest-media {
  background-position: 0 50%;
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 50px;
  padding-bottom: 20px;

  h3 {
    color: rgba(153, 116, 21, 0.94);
  }

  @media(max-width: 480px) {
    background-position-x: 100%;
    padding-top: 20px;
    padding-bottom: 20px;
    background-size: contain;
    .display-4 {
      font-size: 250%;
    }
  }
}

h4.sec-title {
  color: #7B6191;
  position: relative;
  padding-top: 10px;
  @include media-breakpoint-down(sm) {
    margin-bottom: 1.2em;
  }

  &:before {
    position: absolute;
    content: '';
    border-top: 1px solid #7B6191;
    top: 0;
    left: 0;
    width: 100%;
    @include media-breakpoint-down(sm) {
      display: none;
    }
  }

  &:after {
    @include media-breakpoint-down(sm) {
      display: inline-block;
      position: absolute;
      content: "";
      border-bottom: 1px solid #7b6191;
      bottom: -10px;
      left: 0;
      width: 100%;
    }
  }

  &.no-line {
    &:before {
      display: none;
    }
  }
}

#tags {
  display: flex;
  flex-wrap: wrap;
  margin-left: -.3em;
  margin-right: -.3em;

  .tag {
    background-color: #e9ecef;
    margin-left: .3em;
    margin-right: .3em;
    padding: 0 5px;
    border-radius: 4px;
    font-size: .85em;
  }
}

#comment-form {

}

.event-loop {
  width: 100%;
}

.event-wrap {
  display: flex;
  justify-content: flex-start;
  border-bottom: 1px solid #E1E1F8;

  &:hover {
    cursor: pointer;

    .subject {
      color: #785a53;
    }
  }

  &:last-child {
    border-bottom: 0;
  }

  .event-content {
    flex-basis: 100%;
    padding: 1em;
    padding-left: 0;
    position: relative;

    .date {
      background-color: #f0f7ed;
      position: relative;
      padding: 10px;
      font-weight: 700;
      width: 90px;
      height: 90px;
      @include media-breakpoint-down(sm) {
        width: 40px;
        height: 80px;
      }

      .month, .day {
        color: #797985;
        font-size: 2em;
        font-family: 'Bree Serif', serif;
        @include media-breakpoint-down(sm) {
          font-size: 1.2em;
        }
      }

      .month {
        position: absolute;
        top: 0;
        left: 10px;
        @include media-breakpoint-down(sm) {
          top: 5px;
        }
      }

      .day {
        position: absolute;
        right: 10px;
        bottom: 0;
        @include media-breakpoint-down(sm) {
          left: 10px;
          bottom: 5px;
        }
      }

      .line {
        position: absolute;
        width: 80%;
        height: 2px;
        background-color: #d0d6cd;
        transform: rotate(-45deg);
        top: 46px;
        left: 9px;
        @include media-breakpoint-down(sm) {
          transform: rotate(0deg);
          top: 39px;
          left: 0;
          width: 100%;
        }
      }
    }

    .subject {
      position: absolute;
      left: 120px;
      top: 14px;
      font-size: 1.4em;
      font-weight: 700;
      color: #6a6a75;
      @include media-breakpoint-down(sm) {
        left: 60px;
        font-size: 1.2em;
      }
    }

    .date-detail {
      position: absolute;
      left: 122px;
      top: 50px;
      font-size: 1.15em;
      font-weight: 500;
      color: #6a6a75;
      display: flex;
      flex-direction: column;
      @include media-breakpoint-down(sm) {
        left: 60px;
        font-size: 1em;
      }

      .point {
        font-size: 10px;
        color: #c0c0d6;
        position: relative;
        top: -3px;
      }
    }
  }

  .show-detail {
    background-color: #d0d6cd;
    color: #212126;
    display: block;
    position: absolute;
    right: 20px;
    top: 36px;
    padding: 3px 8px;
    font-size: 13px;
    font-weight: 300;
    transition: all 100ms ease-in;

    &:hover {
      color: #322e2d;
      background-color: #B9B89E;
    }

    @include media-breakpoint-down(sm) {
      display: none;
    }
  }
}

#event-poster {
  transition: all 300ms ease-in-out;

  &:hover {
    transform: perspective(600px) rotateY(20deg);
  }
}

#event-detail-table {
  tr {
    th {
      color: #9a8d87;

      .fa {
        width: 20px;
      }
    }
  }
}

#next-gen-wrap {

  .nav {
    .nav-item {
      .nav-link {
        background-color: white;
        color: #9468bb;
        font-weight: 700;
        border: 2px solid #9C2FBB;

        &.active {
          background-color: #9C2FBB;
          color: white;
        }

        &:hover {
          background-color: rgba(159, 47, 190, 0.73);
          color: white;
        }
      }
    }
  }

  section {
    display: flex;

    &:after {
      display: block;
      content: '';
      clear: both;
      width: 100%;
      height: 3em;
    }

    .timetable {
      @include media-breakpoint-down(sm) {
        font-size: 14px;
      }

      th {
        background-color: rgba(77, 52, 153, 0.23);
        width: 100px;
      }
    }
  }
}

#dept-detail {
  section {
    &:after {
      content: '';
      display: block;
      clear: both;
      width: 100%;
      height: 2em;
    }
  }
}

.round-label {
  background-color: rgba(167, 136, 190, 0.73);
  color: white;
  padding: .5rem 1rem;
  border-radius: 30px;
}

#education-section {
  #curicurum {
    display: flex;
    flex-direction: column;
    margin-left: -5px;
    margin-right: -5px;

    .item {
      display: flex;
      justify-content: flex-start;
      padding: 20px;
      background-color: #fbf4f9;
      margin-left: 5px;
      margin-right: 5px;
      margin-bottom: 5px;

      &:nth-child(even) {
        background-color: #f8eafc;
      }

      @include media-breakpoint-down(sm) {
        flex-direction: column;
      }

      .head {
        flex-basis: 240px;

        .t {
          font-weight: 800;
          font-size: 1.2rem;
        }

        p {
          font-size: 1rem;
          margin-bottom: 0;
        }
      }

      ul {
        flex-wrap: wrap;
        flex-basis: calc(100% - 280px);
        padding-left: 2em;
      }

      ul > li {
        font-size: .8rem;

        &:after {
          content: '';
          display: inline-block;
          margin-right: 1em;
        }

        &:last-child::after {
          margin-right: 0;
        }
      }

      @include media-breakpoint-down(sm) {
        .head, ul {
          flex-basis: 100%;
        }
        ul {
          margin-top: 1em;
          padding-left: 0;
        }
      }
    }
  }
}

#new-family-steps {
  .col {
    .card {
      position:relative;
      .card-body {
        @include media-breakpoint-down(sm) {
          padding:6px;
        }
        .card-title {
          @include media-breakpoint-down(sm) {
            font-size:14px;
            line-height:1.3;
          }
        }
      }
      .arrow {
        width: 60px;
        position: absolute;
        right: -46px;
        top: calc(50% - 32px);
        z-index:1080;
        @include media-breakpoint-down(sm) {
          width:40px;
          right:-37px;
        }
      }
    }
    &:nth-child(2n) {
      .arrow {
        @include media-breakpoint-down(sm) {
          display:none;
        }
      }
    }
  }
}

#navigation-to-map {
  .naver {
    background-color: #28bf37;
    color: white;
  }

  .kakao {
    background-color: #ffdf00;
    color: white;
  }

  .google {
    background-color: #f14553;
    color: white;
  }
}

#map {
  min-height: 450px;

  .map-point-info {
    padding: 10px;

    .map-point-title {
      text-align: center;
      font-size: 14px;
    }

    .map-point-content {
      font-size: 12px;
    }
  }
}

#dept-loop {
  .nav-item {
    .nav-link {
      background-color: $color-2;
      margin-right: 8px;
      margin-bottom: 8px;

      &.active {
        background-color: $theme-color;
      }

      @include media-breakpoint-down(sm) {
        font-size: 1em;
        padding: .5em 1em;
      }
    }

    &:last-child {
      .nav-link {
        margin-right: 0;
      }
    }
  }

  .dept {
    margin-bottom: 64px;

    &:last-child {
      margin-bottom: 0;
      border-bottom: 0;
    }

    .dept-intro-wrap {
      padding: 2em;
      background: white;
      max-width: calc(100% - 200px);
      position: relative;
      @include media-breakpoint-down(sm) {
        max-width: 100%;
        padding: 1em;
        background: none;
      }
    }

    .dept-title {
      color: $color-1;
      font-weight: 700;
      border-bottom: 3px solid $color-1;
      margin-bottom: 1.5rem;
      padding-bottom: 1rem;


      .feather {
        top: 0;
      }

      @include media-breakpoint-down(sm) {
        text-align: center;
      }
    }

    .dept-content {
      flex: none;
      width: calc(100% - 220px);
      order: 1;
      @include media-breakpoint-down(sm) {
        order: 2;
        width: 100%;
      }
      .intro {
        font-size: 1em;
        letter-spacing: -0.05em;

        * {
          font-size: inherit;
        }
      }
    }

    .dept-image {
      width: 420px;
      flex: none;
      position: relative;
      right: 20px;
      order: 2;
      @include media-breakpoint-down(sm) {
        margin-left: 0;
        margin-right: 0;
        margin-bottom: 1rem;
        width: 100%;
        order: 1;
        position: static;
      }

      img {
        width: 100%;
        height: auto;
        position: relative;
        z-index: 100;
        @include media-breakpoint-down(sm) {
          display: block;
          margin-left: auto;
          margin-right: auto;
          margin-bottom: 20px;
        }
      }
    }

    h4 {
      font-size: 1.4rem;
      font-weight: 700;
      color: $color-1;
      word-break: keep-all;
    }

    h5 {
      color: $color-1;
      word-break: keep-all;
    }

    .dept-info-wrap {
      .dept-info {
        display: flex;
        margin-bottom: 5px;

        .dept-info-title {
          color: $color-1;
          border-color: $color-1;
          border-style: solid;
          border-width: 1px;
          padding: .2em .8em;
          display: inline-block;
          flex-basis: 120px;
        }

        .dept-info-content {
          flex: 1;
          padding-left: 1em;
          display: inline-flex;
          align-items: center;
        }
      }
    }


    .gallery {
      padding: 0 2em;

      h4 {
        text-align: center;
        font-size: 1.4rem;
        margin-bottom: 32px;
      }

      .lightbox {
        transition: all 200ms ease-in-out;

        .thumbnail {
          box-shadow: 2px 2px 4px rgba(69, 79, 113, 0.3);
        }

        &:hover {
          .thumbnail {
            transform: scale(1.02);
            box-shadow: 4px 4px 8px rgba(69, 79, 113, 0.1);
          }
        }
      }
    }
  }
}

.mce-content-body {
  padding: 1em;
}

#search-result {
  @include media-breakpoint-down(sm) {
    margin-top: -1em;
  }

  .jumbotron {
    background-image: url(images/bg/search-result-bg.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position-x: 89%;
    @include media-breakpoint-down(sm) {
      background-position-x: 100%;
      background-position-y: center;
      background-size: 45%;
      .display-5 {
        font-size: 1.2em;
      }
      .lead {
        font-size: 1em;
      }
    }
  }

  .loop-item {
    padding-top: 1em;
    padding-bottom: 1em;
    margin-bottom: 1em;

    .loop-title {
      font-size: 1.2em;
    }

    .loop-post-info {
      display: flex;
      font-size: .8em;
      margin-bottom: 1em;
    }

    .tags {
      .tag {
        @extend .text-muted;
        font-size: .85em;
      }
    }

    .loop-excerpt {
      font-size: .8em;
    }
  }
}

#staff-tab {
  .nav-item {
    flex-basis: 120px;

    @include media-breakpoint-down(sm) {
      flex: 1;
    }

    &:last-child {
      .nav-link {
        margin-right: 0;
      }
    }

    .nav-link {
      background-color: #c1d1de;
      border-radius: 0;
      color: $text-color;
      margin-left: 10px;
      margin-right: 10px;
      text-align: center;

      &:first-child {
        margin-left: 0;
      }

      &.active {
        background-color: lighten($theme-color, 20%);
        color: white;
      }
    }
  }
}

.nav-pills {
  .nav-item > .nav-link.rounded-pill {
    background-color: #c1d1de;
    &.active {
      background-color: #19a6fc;
    }
  }
}

.staff-group {
  margin-bottom: 2em;

  .staff {
    transition: box-shadow 200ms ease;
    flex: none;

    @include media-breakpoint-down(sm) {
      margin-left: 5px;
      margin-right: 5px;
      flex-basis: calc(33.333% - 10px) !important;
      border-radius: .4rem;
    }

    &:hover {
      box-shadow: 4px 4px 8px rgba(black, .3);
    }

    .back {
      top: 0;
      left: 0;
    }

    .name {
      text-align: center;
      @include media-breakpoint-down(sm) {
        font-size: .9em;
      }
    }

    .dept {
      text-align: center;
      color: #4c4e5c;
      font-size: .85em;
      line-height: 1.4;
      word-break: keep-all;
      @include media-breakpoint-down(sm) {
        font-size: .7em;
      }
    }

    .position {
      color: #4c4e5c;
      margin-left: .5em;
      font-size: .9em;
      @include media-breakpoint-down(sm) {
        font-size: .8em;
      }
    }

    .sns-link .icon {
      width: 20px;
      height: 20px;
    }

    small {
      line-height: 1.4;
    }

    .front {
      .card-body {
        padding-top: .5em;
        padding-bottom: .5em;
        @include media-breakpoint-down(md) {
          padding: .5em .8em;
        }
      }

      .position {
        font-size: 14px;
        @include media-breakpoint-down(sm) {
          font-size: .8em;
        }
      }

      .name {
        margin-bottom: 8px;
        @include media-breakpoint-down(sm) {
          font-size: .9em;
        }
      }

      .dept {
        font-size: 14px;
        margin-bottom: 15px;
        margin-top: 15px;
        position: relative;
        @include media-breakpoint-down(sm) {
          font-size: .6em;
        }

        &:after {
          content: '';
          width: 30%;
          height: 2px;
          position: absolute;
          left: 35%;
          bottom: -8px;
          background-color: #c4bcb7;
        }
      }
    }
  }

  .card.staff-profile {
    @include media-breakpoint-down(sm) {
      flex-basis: 62% !important;
    }
  }
}

#staff-single {
  .icon {
    width: 32px;
  }

  .sns-item {
    margin-bottom: .5em;
  }

  .sns-icon {
    width: 20px;
    height: 20px;
  }
}

#bible-study {
  .steps {
    .step {
      @include media-breakpoint-down(sm) {
        flex-direction: column;
        align-items: flex-start;
      }

      .step-title {
        background: none;
        border-radius: 0;
        @include media-breakpoint-down(sm) {
          margin-bottom: 0;
        }

        img {
          max-width: 100%;
          height: auto;
          border-radius: 10px;
          box-shadow: 4px 4px 8px rgba(0, 0, 0, .2);
          @include media-breakpoint-down(sm) {
            width: 140px;
            height: 140px;
          }
        }
      }

      .step-content {
        padding-left: 30px;
        @include media-breakpoint-down(sm) {
          padding-left: 0;
        }

        .level {
          font-size: 1.25em;
          font-weight: 800;
          color: $theme-secondary-color;
          @include media-breakpoint-down(sm) {
            text-align: center;
          }
        }

        p {
          padding-left: 0;
        }
      }
    }
  }
}

.support-container {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.support-title-box {
  background-image: url('/images/bg/mission-title-bg.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 140px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  padding: 0 32px;
  margin: 0 -5px;
  @include media-breakpoint-down(sm) {
    background-position: 80% 50%;
    height: 100px;
  }
}

.support-group-item {
  flex-basis: 15.7%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-left: 5px;
  margin-right: 5px;
  margin-bottom: 10px;
  text-align: center;
  border: 1px solid $color-1;
  padding: 8px 5px;
  font-size: 14px;
  min-height: 60px;
  @include media-breakpoint-down(sm) {
    flex-basis: calc(33% - 10px);
  }
}

dl.course-info-item {
  margin: 0;
  display: flex;
  border: 1px solid #e9dff4;
  @include media-breakpoint-down(sm) {
    font-size: .9em;
  }

  dt {
    flex-basis: 120px;
    background-color: #eae2ff;
    color: $theme-secondary-color;
    padding: 10px 14px;
    text-align: center;
  }

  dd {
    flex: 1;
    padding: 10px 14px;
    margin: 0;
  }
}

.course-info-group {
  border-top: 1px solid #e9dff4;
  margin-bottom: 1em;
  display: flex;
  flex-wrap: wrap;

  .course-info-item {
    border: 0;
    border-bottom: 1px solid #e9dff4;
    flex-basis: calc(50% - 2px);

    &:nth-child(2n) {
      border-right: 1px solid #e9dff4;
    }

    @include media-breakpoint-down(sm) {
      flex-basis: 100%;
      border-right: 1px solid #e9dff4;
    }
  }
}

#related-organization {
  max-width: 100%;
  overflow-x: hidden;
}

.kakao-card {
  font-size: 14px;
  width: 260px;
  border-radius: 16px;
  overflow: hidden;
  transition: all 100ms ease-out;

  &:hover {
    box-shadow: 0 0 8px rgba(black, .2);
    transform: translate(-1px, -1px);
    cursor: pointer;
  }

  @include media-breakpoint-down(sm) {
    width: 100%;
  }

  img.card-img-top {
    margin-bottom: 14px;
  }

  .card-body {
    padding-top: 0;
  }

  .like-trigger {
    .feather-heart {
      transition: all 100ms ease-in-out;

      &.filled {
        stroke: #ff6275 !important;
        fill: #ff6275 !important;
      }
    }

    &:hover {
      .feather-heart {
        stroke: #ff6275 !important;
        fill: #ff6275 !important;
        width: 1.2em;
        height: 1.2em;
      }
    }
  }

  .card-footer {
    background-color: transparent;
    font-size: .8em;
  }

  .like-trigger, .date-wrapper, .writer-wrapper {
    display: inline-block;
    margin-right: 5px;
  }
}

.quote {
  font-family: 'Noto Serif KR', serif;
  font-weight: 400;
  font-size: 1.2rem;
  margin: 60px auto;
  text-align: center;
  position: relative;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  @include media-breakpoint-down(sm) {
    font-size: 1rem;
    padding: 1rem;
  }

  &:before {
    content: '';
    display: block;
    width: 48px;
    height: 48px;
    position: absolute;
    top: -48px;
    left: calc(50% - 24px);
    background-image: url(/images/icons/quote.png);
    background-size: cover;
    @include media-breakpoint-down(sm) {
      width: 32px;
      height: 32px;
      left: calc(50% - 16px);
      top: -32px;
    }
  }

  &:after {
    content: '';
    display: block;
    width: 48px;
    height: 48px;
    position: absolute;
    bottom: -48px;
    left: calc(50% - 24px);
    background-image: url(/images/icons/quote.png);
    background-size: cover;
    transform: rotate(180deg);
    @include media-breakpoint-down(sm) {
      width: 32px;
      height: 32px;
      left: calc(50% - 16px);
      bottom: -32px;
    }
  }
}

.intro-section {
  .intro-flex {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto 32px;
    @include media-breakpoint-down(sm) {
      flex-direction: column;
    }

    .intro-featured {
      flex-basis: 30%;
      @include media-breakpoint-down(sm) {
        flex-basis: 100%;
        order: 2;
      }

      img {
        width: 100%;
      }

      &.intro-featured-wide {
        flex-basis: 40%;
      }
    }

    .intro-leading {
      flex-basis: 50%;
      padding-left: 2rem;
      font-family: 'Noto Serif', serif;
      @include media-breakpoint-down(sm) {
        order: 1;
        flex-basis: 100%;
        padding: 0 !important;
      }

      p {
        font-size: 1.25rem;
        letter-spacing: -.08em;
        @include media-breakpoint-down(sm) {
          text-align: center;
          font-size: 1.1rem;
        }
      }
    }
  }
}

.card-group-bgcolor {
  .card {
    .card-header {
      color: white;
    }

    &:nth-child(odd) {
      .card-header {
        background-color: $color-1;
      }
    }

    &:nth-child(even) {
      .card-header {
        background-color: darken($color-3, 10%);
      }
    }
  }
}

.timeline-horizontal {
  position: relative;

  .line {
    width: 100%;
    height: 4px;
    background-color: $theme-color;
  }

  .timeline-container {
    display: flex;
    justify-content: center;
    width: 100%;

    .timeline-item {
      @extend .rounded-pill;
      flex: 1;
      padding: 1rem;
      margin: 20px .5rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      border: 1px solid $theme-color;
      position: relative;

      &:before {
        content: '';
        display: block;
        width: 16px;
        height: 16px;
        border: 4px solid $theme-color;
        border-radius: 50%;
        background-color: white;
        position: absolute;
        top: -30px;
        left: calc(50% - 8px);
      }

      &:after {
        content: '';
        position: absolute;
        display: block;
        left: calc(50% - 2px);
        top: -14px;
        width: 4px;
        height: 14px;
        background-color: $theme-color;
      }

      .time {
        text-align: center;
        color: $theme-color;
        line-height: 1.1;
      }

      .content {
        font-weight: 700;
        line-height: 1.1;
        padding: 10px 0;
        word-break: keep-all;
        text-align: center;
      }
    }
  }
}

// 인사말
#church-welcome {
  background-image: url(https://s3.ap-northeast-2.amazonaws.com/gjp.or.kr/uploads/2020-02/ZBL1xn5USzpaQMEmIJHCUluZcEAdefxH7F98VQGW.png);
  background-position: 100% 90%;
  background-size: 70%;
  background-repeat: no-repeat;

  .describe {
    margin-top: 32px;
    margin-bottom: 32px;
    @include media-breakpoint-up(md) {
      max-width: 60%;
    }
  }

  .signature {
    display: block;

    strong {
      display: inline-block;
      font-weight: 700;
      font-size: 1.12em;
      letter-spacing: .3rem;
      margin-left: 1em;
    }
  }
}

.facilities {
  h4 {
    text-align: center;
  }

  .row {
    .col-sm-2 {
      h5 {
        display: inline-block;
        padding: .5em 1.5em;
        background-color: var(--primary);
        color: white;
        border-radius: 2em;
      }
    }
  }

  .card-deck {
    .card {
      max-width: 15em;
      margin-bottom: 1.5em;
      border: none;

      .card-img-top {
        border-radius: 0;
      }

      .card-footer {
        background-color: white;
        text-align: center;
      }
    }
  }
}

// 비전과 소명
#vision-and-calling {
  h3 {
    text-align: center;
    font-size: 2rem;
    position: relative;
    margin-bottom: 32px;
    @include media-breakpoint-down(sm) {
      font-size: 1.4rem;
    }
  }

  > img {
    margin-bottom: 32px;
  }

  .quote {
    max-width: 800px;
  }

  > .quote {
    max-width: 640px;
  }

  .circle-rotation-container {
    display: flex;
    align-items: flex-start;
    @include media-breakpoint-down(sm) {
      flex-direction: column;
    }

    img {
      max-width: 680px;
      width: 100%;
      height: auto;
      margin-bottom: 32px;
      @include media-breakpoint-down(sm) {
        max-width: initial;
      }
    }

    .circles {
      display: flex;
      flex-direction: column;
      align-items: stretch;
      margin-left: 1rem;
      @include media-breakpoint-down(sm) {
        margin-left: 0;
      }

      .circle-item {
        margin-bottom: .5rem;
        word-break: keep-all;

        .title {
          font-size: 1.05rem;
          font-weight: 700;
          text-align: left;
          margin-bottom: .5rem;
          padding: .5rem 1rem;
          background: $theme-color url('/images/pattern/slash-pattern-reverse.png') repeat;
          background-size: 70%;
          color: white;
          border-radius: 32px;
          @include media-breakpoint-down(sm) {
            border-radius: 10px;
            font-size: 1rem;
            text-align: center;
          }
        }

        ul {
          list-style: none;
          @include media-breakpoint-down(sm) {
            padding-left: 0;
          }

          li {
            text-align: left;
            @include media-breakpoint-down(sm) {
              text-align: center;
            }
          }
        }
      }
    }
  }

  .vision-container {
    display: flex;
    margin: 0 -.5rem;
    width: 100%;
    justify-content: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
    @include media-breakpoint-down(sm) {
    }

    .figure {
      flex-basis: 50%;
      margin-bottom: 2rem;
      display: flex;
      flex-direction: row;
      align-items: flex-start;

      padding: 0 1.4rem;
      background: white;
      border-radius: 1rem;

      @include media-breakpoint-down(sm) {
        flex: 100%;
      }

      .circle {
        flex-basis: 80px;
        color: $color-3;
        font-size: 24px;
        font-weight: 700;
        @include media-breakpoint-down(sm) {
          border: 4px solid white;
          font-size: 18px;
          width: 80px;
          height: 80px;
        }
      }

      figcaption {
        flex-basis: calc(100% - 80px);
        padding-left: 32px;
        border-left: 4px solid lighten($color-1, 10%);
        word-break: keep-all;

        .caption-title {
          text-align: left;
          color: $color-1;
          font-weight: 700;
          font-size: 1.2em;
          line-height: 1.4;
        }
      }
    }
  }


}

// 교회 역사
#history-section {
  margin-bottom: 32px;

  h3 {
    position: relative;
    padding-left: 1rem;

    &::before {
      content: '';
      background-color: transparentize($theme-third-color, .75);
      width: 3rem;
      height: 3rem;
      border-radius: 50%;
      position: absolute;
      left: 0;
      top: -1rem;
    }
  }

  img {
    border-radius: 8px;
  }
}

#history-group {
  .nav-item {
    .nav-link {
      background-color: $light;
      margin-right:10px;
      margin-bottom:10px;
      &.active {
        background-color: $primary;
        color:white;
      }
      &:hover {
        background-color: lighten($primary, 10%);
        color:white;
      }
    }
  }
}

/*#history {
  #eras {
    width: 100%;
    padding: 0 42px;
    position: relative;
    border-bottom: 1px solid $theme-color;

    .era-item {
      padding: .2em .5em;
      font-size: 1.5em;
      font-weight: 300;
      font-style: italic;
      font-family: Lato, sans-serif;

      a {
        color: $theme-color;
        font-family: Lato, sans-serif;
        font-weight: 700;

        &:hover {
          color: lighten($theme-color, 10%);
          font-weight: 700;
        }
      }
    }

    .slick-prev:before, .slick-next:before {
      font-size: 28px;
      color: rgba($theme-secondary-color, .6);
    }

    .slick-prev {
      left: 0;
    }

    .slick-next {
      right: 10px;
    }
  }

  #history-contents {
    margin: 24px 0 1em;
  }



  .history-item {
    display: flex;
    width:50%;
    justify-content: flex-start;
    padding: .85rem .5rem;
    border-bottom: 1px solid rgba($theme-color, 0.2);
    position: absolute;
    left: 0;

    &:nth-child(even) {
      background-color: #7f6d630a;
      left: 50%;
    }

    @include media-breakpoint-down(sm) {
      font-size: 1em;
      flex-wrap: wrap;
    }

    .date {
      color: $theme-color;
      width: 220px;
      flex: none;
      font-family: Lato, sans-serif;
      @include media-breakpoint-down(sm) {
        font-weight: 700;
      }
    }
  }
}*/

// CI
#ci {
  .quote {
    max-width: 640px;
    margin: 80px auto;
  }

  .ci-item {
    @include media-breakpoint-down(sm) {
      margin-bottom: 30px;
    }

    img {
      margin-bottom: 30px;
    }

    p {
      text-align: center;
    }
  }
}

// 회원가입폼
#regist-form {
  .form-group {
    input::placeholder {
      font-size: 12px;
    }

    .input-group {
      .input-group-prepend {
        min-width: 35%;

        .input-group-text {
          width: 100%;
          font-size: 14px;

          &.required {
            &:before {
              display: inline-block;
              content: '필수';
              color: #ff747f;
              font-size: .8em;
              margin-right: 5px;
            }
          }
        }
      }
    }
  }
}

span.number {
  display: inline-block;
  width: 1.4em;
  height: 1.4em;
  font-size: .8em;
  line-height: 1.4;
  text-align: center;
  margin-right: 1em;
  background-color: #182247;
  color: white;
  border-radius: 6px;
}

#history-carousel-container {
  position: relative;
  padding-left: 40px;
  @include media-breakpoint-down(sm) {
    padding-left: 30px;
  }

  &:before {
    display: block;
    content: '';
    position: absolute;
    left: 50%;
    top: 0;
    bottom: 0;
    width: 2px;
    background-color: rgba(black, .2);
    @include media-breakpoint-down(sm) {
      left: 10px;
    }
  }

  .history-item {
    margin-bottom: 30px;
    width:50%;
    position: relative;
    @include media-breakpoint-down(md) {
      width: 50%;
    }

    .history-date {
      color: #1f272f;
      margin-bottom: 10px;
      @include media-breakpoint-down(md) {
        margin-bottom: 10px;
      }

      &:before {
        display: block;
        width: 20px;
        height: 20px;
        border: 4px solid $color-1;
        background-color: white;
        border-radius: 10px;
        content: '';
        position: absolute;
        left: -14px;
        top: 7px;
        @include media-breakpoint-down(md) {
          top: 4px;
        }
      }
    }

    .history-content {
      display:flex;
      color: #5d6471;
      margin-bottom: 30px;
      @include media-breakpoint-down(md) {
        margin-bottom:0;
      }

      p {
        letter-spacing: -0.05em;
        max-width:70%;
        @include media-breakpoint-down(md) {
          max-width:100%;
        }
      }
    }

    &:nth-child(odd) {
      left:50%;
      @include media-breakpoint-down(md) {
        left: 0;
        width:100%;
      }
    }
    &:nth-child(even) {
      text-align:right;
      padding-right: 40px;
      @include media-breakpoint-down(md) {
        text-align:left;
        padding-right:initial;
        width:100%;
      }
      .history-date::before {
        left: 99.3%;
        @include media-breakpoint-down(md) {
          left: -14px;
        }
      }
      .history-content {
        justify-content:flex-end;
        @include media-breakpoint-down(md) {
          justify-content:flex-start;
        }
      }
    }
  }
}

// Welcome page
#core-values {
  background:url(/storage/uploads/2023-12/VIKygBceEXTFOSXZopDnbhSBLNC1Nf2rGsQPUaoK.jpg) no-repeat center;
  background-size: cover;
  position:relative;
  &::before {
    content: '';
    position:absolute;
    width:100%;
    height:100%;
    background-color:rgba(0,0,0,.4);
    background-image:url(/storage/uploads/2023-12/R4TdAX3l5KwpSWh6jYDnyqecogBFiRcNFDbgrB9J.jpg);
    background-size:cover;
    background-repeat:no-repeat;
    background-position:center;
    opacity: .6;
    left:0;
    top:0;
  }
  h2 {
    justify-content:center;
    text-shadow: 0 0 4px black;
    &::after {
      display:none;
    }
  }
  .card {
    background-color:rgba(0,0,0, .4);
    color: white;
    .card-body {
      display:flex;
      flex-direction:column;
      justify-content:center;
      min-height: 150px;
      text-shadow: 0 0 2px black;
      @media screen and (max-width:480px) {
        min-height: 180px;
      }
      h3 {
        font-size: 20px;
        font-weight: bold;
      }
    }
  }
}

#sub-category-filters {
  .nav-item {
    .nav-link {
      background-color: #e7e7e7;
      font-size: .9em;
      &.active {
        background-color:$theme-color !important;
      }
    }
  }
}
