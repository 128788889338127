$border-style : 1px solid #ccc;
.table-wrap {
  display: flex;
  flex-direction: column;
  width: 100%;
  border: $border-style;
  justify-content: stretch;
  margin-bottom: .5rem;

  @include media-breakpoint-down(sm) {
    flex-direction:column;
    border:0;
  }

  .cell {
    border-right: $border-style;
    border-bottom: $border-style;
    padding: 10px;
    @include media-breakpoint-down(sm) {
      border: $border-style;
    }

    &.title {
      font-weight: bold;
      background-color: rgba($theme-color, .2);
      @include media-breakpoint-down(sm) {
        border-bottom:0;
      }
    }
  }

  .t-col {
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    @include media-breakpoint-down(sm) {
      margin-bottom:0;
    }

    &:last-child {
      .cell {
        border-right: 0;
      }
    }
  }

  .t-row {
    display: flex;
    flex-direction: row;
    justify-content: stretch;
    flex-grow: 1;

    @include media-breakpoint-down(sm) {
      flex-direction:column;
    }

    > .t-col:last-child {
      .cell {
        border-right:0;
        @include media-breakpoint-down(sm) {
          border-right:$border-style;
        }
      }
    }

    &:last-child {
      .t-col {
        > .cell:last-child {
          border-bottom:0;
          @include media-breakpoint-down(sm) {
            border-bottom:$border-style;
          }
        }
      }
    }
  }
}
