// Body
/* Color Theme Swatches in Hex */
$color-1: #0095ff;
$color-2: #F2F2F2;
$color-3: #8C8C8C;
$color-4: #262626;
$color-5: #0D0D0D;

$body-bg: #fff;
$theme-color: $color-1;
$theme-secondary-color: $color-4;
$theme-third-color: $color-3;
$primary : #0645ad;
$text-color : $color-4;
$submenu-color: #2e82d3;
$page-header-color: #434087;


// Typography
$font-family-sans-serif: "Spoqa Han Sans Neo", "Noto Sans KR", "Apple Gothic SD", malgungothic, "맑은고딕", sans-serif;
$font-family-serif: 'Noto Sans Serif','바탕', batang, serif;
$font-size-base: 1rem;
$font-weight-base: 400;
$line-height-base: 1.6;
$font-size-sm : .85rem;

$link-color: rgb(41, 46, 56);
$link-hover-color: $theme-color;
$link-hover-decoration:none;

$enable-gradients: true;
$custom-file-text: (en: "Browse",ko: "파일첨부");

.card {
	.card-title {
		font-size: .95em;
	}
}

@import '~bootstrap/scss/bootstrap';
@import '~pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';
@import "~plyr/dist/plyr.css";
@import "~animate.css/animate.css";
@import "~slick-carousel/slick/slick";
@import "~slick-carousel/slick/slick-theme";
@import "~magnific-popup/dist/magnific-popup.css";

/*스포카 한 산스 네오 서브셋*/
@font-face {
	font-family: 'Spoqa Han Sans Neo';
	font-weight: 700;
	src: local('Spoqa Han Sans Neo Bold'),
	url('/fonst/spoqa-han-sans-seol/SpoqaHanSansNeo-Bold.woff2') format('woff2'),
	url('/fonst/spoqa-han-sans-seol/SpoqaHanSansNeo-Bold.woff') format('woff');
}


@font-face {
	font-family: 'Spoqa Han Sans Neo';
	font-weight: 500;
	src: local('Spoqa Han Sans Neo Medium'),
	url('/fonst/spoqa-han-sans-seol/SpoqaHanSansNeo-Medium.woff2') format('woff2'),
	url('/fonst/spoqa-han-sans-seol/SpoqaHanSansNeo-Medium.woff') format('woff');
}

@font-face {
	font-family: 'Spoqa Han Sans Neo';
	font-weight: 400;
	src: local('Spoqa Han Sans Neo Regular'),
	url('/fonst/spoqa-han-sans-seol/SpoqaHanSansNeo-Regular.woff2') format('woff2'),
	url('/fonst/spoqa-han-sans-seol/SpoqaHanSansNeo-Regular.woff') format('woff');
}

@font-face {
	font-family: 'Spoqa Han Sans Neo';
	font-weight: 300;
	src: local('Spoqa Han Sans Neo Light'),
	url('/fonst/spoqa-han-sans-seol/SpoqaHanSansNeo-Light.woff2') format('woff2'),
	url('/fonst/spoqa-han-sans-seol/SpoqaHanSansNeo-Light.woff') format('woff');
}

@font-face {
	font-family: 'Spoqa Han Sans Neo';
	font-weight: 100;
	src: local('Spoqa Han Sans Neo Thin'),
	url('/fonst/spoqa-han-sans-seol/SpoqaHanSansNeo-Thin.woff2') format('woff2'),
	url('/fonst/spoqa-han-sans-seol/SpoqaHanSansNeo-Thin.woff') format('woff');
}

/* noto-serif-kr-300 - korean_latin */
@font-face {
	font-family: 'Noto Serif KR';
	font-style: normal;
	font-weight: 300;
	src: url('/fonts/noto-serif-kr/noto-serif-kr-v6-korean_latin-300.eot'); /* IE9 Compat Modes */
	src: local('Noto Serif KR Light'), local('NotoSerifKR-Light'),
	url('/fonts/noto-serif-kr/noto-serif-kr-v6-korean_latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
	url('/fonts/noto-serif-kr/noto-serif-kr-v6-korean_latin-300.woff2') format('woff2'), /* Super Modern Browsers */
	url('/fonts/noto-serif-kr/noto-serif-kr-v6-korean_latin-300.woff') format('woff'), /* Modern Browsers */
	url('/fonts/noto-serif-kr/noto-serif-kr-v6-korean_latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
	url('/fonts/noto-serif-kr/noto-serif-kr-v6-korean_latin-300.svg#NotoSerifKR') format('svg'); /* Legacy iOS */
}
/* noto-serif-kr-regular - korean_latin */
@font-face {
	font-family: 'Noto Serif KR';
	font-style: normal;
	font-weight: 400;
	src: url('/fonts/noto-serif-kr/noto-serif-kr-v6-korean_latin-regular.eot'); /* IE9 Compat Modes */
	src: local('Noto Serif KR'), local('NotoSerifKR-Regular'),
	url('/fonts/noto-serif-kr/noto-serif-kr-v6-korean_latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
	url('/fonts/noto-serif-kr/noto-serif-kr-v6-korean_latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
	url('/fonts/noto-serif-kr/noto-serif-kr-v6-korean_latin-regular.woff') format('woff'), /* Modern Browsers */
	url('/fonts/noto-serif-kr/noto-serif-kr-v6-korean_latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
	url('/fonts/noto-serif-kr/noto-serif-kr-v6-korean_latin-regular.svg#NotoSerifKR') format('svg'); /* Legacy iOS */
}
/* noto-serif-kr-700 - korean_latin */
@font-face {
	font-family: 'Noto Serif KR';
	font-style: normal;
	font-weight: 700;
	src: url('/fonts/noto-serif-kr/noto-serif-kr-v6-korean_latin-700.eot'); /* IE9 Compat Modes */
	src: local('Noto Serif KR Bold'), local('NotoSerifKR-Bold'),
	url('/fonts/noto-serif-kr/noto-serif-kr-v6-korean_latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
	url('/fonts/noto-serif-kr/noto-serif-kr-v6-korean_latin-700.woff2') format('woff2'), /* Super Modern Browsers */
	url('/fonts/noto-serif-kr/noto-serif-kr-v6-korean_latin-700.woff') format('woff'), /* Modern Browsers */
	url('/fonts/noto-serif-kr/noto-serif-kr-v6-korean_latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
	url('/fonts/noto-serif-kr/noto-serif-kr-v6-korean_latin-700.svg#NotoSerifKR') format('svg'); /* Legacy iOS */
}

/* noto-sans-kr-300 - korean_latin */
@font-face {
	font-family: 'Noto Sans KR';
	font-style: normal;
	font-weight: 300;
	src: url('/fonts/noto-sans-kr/noto-sans-kr-v12-korean_latin-300.eot'); /* IE9 Compat Modes */
	src: local('Noto Sans KR Light'), local('NotoSansKR-Light'),
	url('/fonts/noto-sans-kr/noto-sans-kr-v12-korean_latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
	url('/fonts/noto-sans-kr/noto-sans-kr-v12-korean_latin-300.woff2') format('woff2'), /* Super Modern Browsers */
	url('/fonts/noto-sans-kr/noto-sans-kr-v12-korean_latin-300.woff') format('woff'), /* Modern Browsers */
	url('/fonts/noto-sans-kr/noto-sans-kr-v12-korean_latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
	url('/fonts/noto-sans-kr/noto-sans-kr-v12-korean_latin-300.svg#NotoSansKR') format('svg'); /* Legacy iOS */
}
/* noto-sans-kr-regular - korean_latin */
@font-face {
	font-family: 'Noto Sans KR';
	font-style: normal;
	font-weight: 400;
	src: url('/fonts/noto-sans-kr/noto-sans-kr-v12-korean_latin-regular.eot'); /* IE9 Compat Modes */
	src: local('Noto Sans KR Regular'), local('NotoSansKR-Regular'),
	url('/fonts/noto-sans-kr/noto-sans-kr-v12-korean_latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
	url('/fonts/noto-sans-kr/noto-sans-kr-v12-korean_latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
	url('/fonts/noto-sans-kr/noto-sans-kr-v12-korean_latin-regular.woff') format('woff'), /* Modern Browsers */
	url('/fonts/noto-sans-kr/noto-sans-kr-v12-korean_latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
	url('/fonts/noto-sans-kr/noto-sans-kr-v12-korean_latin-regular.svg#NotoSansKR') format('svg'); /* Legacy iOS */
}
/* noto-sans-kr-700 - korean_latin */
@font-face {
	font-family: 'Noto Sans KR';
	font-style: normal;
	font-weight: 700;
	src: url('/fonts/noto-sans-kr/noto-sans-kr-v12-korean_latin-700.eot'); /* IE9 Compat Modes */
	src: local('Noto Sans KR Bold'), local('NotoSansKR-Bold'),
	url('/fonts/noto-sans-kr/noto-sans-kr-v12-korean_latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
	url('/fonts/noto-sans-kr/noto-sans-kr-v12-korean_latin-700.woff2') format('woff2'), /* Super Modern Browsers */
	url('/fonts/noto-sans-kr/noto-sans-kr-v12-korean_latin-700.woff') format('woff'), /* Modern Browsers */
	url('/fonts/noto-sans-kr/noto-sans-kr-v12-korean_latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
	url('/fonts/noto-sans-kr/noto-sans-kr-v12-korean_latin-700.svg#NotoSansKR') format('svg'); /* Legacy iOS */
}

.btn-primary {
	color:white;
}

.font-size-sm {
	font-size: $font-size-sm;
	* {
		font-size:$font-size-sm;
	}
}
.pagination {
	a {
		font-family:Lato, sans-serif;
	}
}

#fileuploadzone {
	height:600px;
	width:calc(100% - 240px);
	background-color: rgba(238, 238, 238, 0.9);
	position:fixed;
	top:100px;
	left:240px;
	padding:1em;
	border:3px solid #ccc;
	overflow-y:auto;
	z-index:1000;
	.lead {
		width:100%;
	}

	@include media-breakpoint-down(sm) {
		height: calc(100vh - 70px);
		width: 100%;
		top: 70px;
	}
}
#fileuploadzone2 {
	@extend #fileuploadzone;
	min-height:100px;
	height:initial;
	width:100%;
	position:relative;
	top:0;
	left:0;
	margin-bottom:1em;
	border:3px dashed #ccc;
}
#closeUploadzone {
	position:absolute;
	top: .5em;
	right:.5em;
}
#previews {
	display:flex;
	width:100%;
	margin-bottom:10px;
	justify-content:flex-start;
	flex-wrap:wrap;
}

.file-row {
	display:flex;
	flex-direction:column;
	flex-basis:20%;
	align-items:center;
	margin-right:20px;
	margin-bottom:20px;
}

.custom-file-input {
	@extend .rounded-0;
}


// Single Post
.entry_post_info_bar {
	list-style-type: none;
	padding-left:0;
	padding-top:10px;
	padding-bottom:10px;
	border-top:1px solid #eee;
	border-bottom:1px solid #eee;
	margin-bottom:30px;
	text-align:center;
	li {
		display:inline-block;
		margin-left:1em;
		margin-right:1em;
		&:first-child {
			margin-left:0;
		}
		&:last-child {
			margin-right:0;
		}
	}
}

.hide {
	display:none;
}

.mj {
	font-family: $font-family-serif;
	letter-spacing: -0.05em;
}

.btn-brown {
		border-color: #9e6c09;
		background-color: #9e6c09;
		color:white;
	&:hover {
		color:white;
		border-color: #5D3C06;
		background-color: #5D3C06;
	}
}

.card {
	display:block !important;
}
.img-thumbnail {
	width:100%;
}

.text-menu {
	color:white;
	&.text-active {
		color: #fd60ab !important;
		font-weight:700;
	}
}

#alert-zone {
	width:300px;
	top:150px;
	height:calc(100vh - 150px);
	right:1em;
	overflow-y:auto;
	overflow-x:hidden;
	.toast {
		z-index:1050;
		.toast-header {
			background-color:#5090A5;
			color:white;
			.feather {
				position:relative;
				width:1.2em;
				height:1.2em;
				margin-right:5px;
				top:-.1em;
			}
		}
	}
}

.datetime-icon {
	position:relative;
	display:inline-block;
	width:1.2em;
	height:1.2em;
	&.datetime-icon-clock {
		background:url(/images/datetime-icons/clock.svg) center no-repeat;
	}
	&.datetime-icon-calendar {
		background:url(/images/datetime-icons/calendar.svg) center no-repeat;
	}
	&.datetime-icon-arrow-up {
		background:url(/images/datetime-icons/arrow-up.svg) center no-repeat;
	}
	&.datetime-icon-arrow-down {
		background:url(/images/datetime-icons/arrow-down.svg) center no-repeat;
	}
	&.datetime-icon-chevron-left {
		background:url(/images/datetime-icons/chevron-left.svg) center no-repeat;
	}
	&.datetime-icon-chevron-right {
		background:url(/images/datetime-icons/chevron-right.svg) center no-repeat;
	}
	&.datetime-icon-check {
		background:url(/images/datetime-icons/check.svg) center no-repeat;
	}
	&.datetime-icon-trash {
		background:url(/images/datetime-icons/trash.svg) center no-repeat;
	}
	&.datetime-icon-circle {
		background:url(/images/datetime-icons/circle.svg) center no-repeat;
	}
}
